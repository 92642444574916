import axios from "axios";
import { downloadFile } from "common/utility";

const API_URL =
  typeof window !== "undefined" && window.location.origin.includes("dev")
    ? "https://dev-apis.hommies.io/api"
    : typeof window !== "undefined" &&
      window.location.origin.includes("localhost")
    ? "https://dev-apis.hommies.io/api"//"http://localhost:9001/api"
    : "https://apis.hommies.io/api";

export const axiosApi = axios.create({
  baseURL: API_URL,
});

export async function get(url, config = {}) {
  return await axiosApi
    .get(url, { ...config })
    .then((response) => response.data);
}
export async function getWithExportFile(url, config = {}) {
  return await axiosApi.get(url, { ...config }).then((response) => {
    if (response.status && !response.data.data) {
      const fileUrl = URL.createObjectURL(
        new Blob([response.data], { type: `text/csv` })
      );
      downloadFile(fileUrl, `${url.split("/")[1]}_${Date.now()}.csv`);
    }
    return response.data;
  });
}

export async function postWithExportFile(url, data, config = {}) {
  return await axiosApi
    .post(url, { ...data }, { ...config })
    .then((response) => {
      if (response.status && !response.data.data) {
        const fileUrl = URL.createObjectURL(
          new Blob([response.data], { type: `text/csv` })
        );
        downloadFile(fileUrl, `${url.split("/")[1]}_${Date.now()}.csv`);
      }
      return response.data;
    });
}

export async function post(url, data, config = {}) {
  return axiosApi
    .post(url, { ...data }, { ...config })
    .then((response) => response.data);
}

export async function postAsForm(url, data, config = {}) {
  return axiosApi
    .post(url, data, { ...config })
    .then((response) => response.data);
}
export async function putAsForm(url, data, config = {}) {
  return axiosApi
    .put(url, data, { ...config })
    .then((response) => response.data);
}

export async function put(url, data, config = {}) {
  return axiosApi
    .put(url, { ...data }, { ...config })
    .then((response) => response.data);
}

export async function del(url, id, config = {}) {
  return await axiosApi
    .delete(
      url,
      {
        params: { id },
      },
      { ...config }
    )
    .then((response) => response.data);
}

export const apiHeader = () => {
  let obj = "";
  if (typeof window !== "undefined") {
    obj = JSON.parse(localStorage.getItem("authUser"));
  }
  if (obj && obj.token) {
    axiosApi.defaults.headers.common["Authorization"] = obj.token;
  } else {
    return {};
  }
};
