import abi from "./stakingAbi.json"
import hommies from "./hommiesAbi.json"
import liveHommiesAbi from "./liveHommiesAbi.json"
import liveStakingAbi from "./liveStakingAbi.json"

const devConfig = {
    stakeContractAddress: "0x38e0F9481Dc5d67aff4Ecdacdd4F9D0CE70A0874",
    stakeAbi: abi,
    scanURL: "https://sepolia.etherscan.io/tx",
    hommiesContractAddress: "0x7C37eeBac52A72A57bb5Bf52a5132343500f5b80",
    hommiesAbi: hommies,
    publicProviderKey: "https://eth-sepolia.g.alchemy.com/v2/JVT988g3P353kJTLxhfUQtIOIGceseaW", //"https://go.getblock.io/d04802db88964d8fa5ef1509648ec1f0",
    secondsInDay: 1,
}

const liveConfig = {
    stakeContractAddress: "0x589e49148De57a5d4972834f2A0C7936166D6Add",
    stakeAbi: liveStakingAbi,
    scanURL: "https://etherscan.io/tx",
    hommiesContractAddress: "0x8A370C951F34e295B2655B47bB0985dD08d8f718",
    hommiesAbi: liveHommiesAbi,
    publicProviderKey: "https://eth-mainnet.g.alchemy.com/v2/JVT988g3P353kJTLxhfUQtIOIGceseaW",
    secondsInDay: 86400,
}

export const config =
    typeof window !== "undefined" && window.location.origin.includes("dev")
        ? devConfig
        : typeof window !== "undefined" &&
            window.location.origin.includes("localhost")
            ? devConfig
            : liveConfig