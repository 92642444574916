import React, { useContext, useEffect, useState } from "react";
import { withRouter, Link, useNavigate } from "react-router-dom";
import { Card, CardBody, Col, Badge, Row } from "reactstrap";
import { Table, Thead, Tbody, Tr, Th, Td } from "react-super-responsive-table";
import {
  deleteCategoryData,
  getAllCategory,
  deleteSubCategoryData,
} from "services/helper";
import AddCategory from "./AddCategory";
import { alerShow } from "admin/commonFunction";
import AddSubCategory from "./AddSubCategory";

function Category() {
  const [Data, setdata] = useState();
  const [open, setOpen] = useState(false);
  const [openSub, setSubOpen] = useState(false);

  const fetchData = async () => {
    try {
      await getAllCategory().then((res) => {
        console.log(res.data, ">>>>>>>>>");
        setdata(res.data);
      });
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const ConfirmDelete = (id) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        deleteCategory(id);
      }
    });
  };
  const ConfirmSubDelete = async (id) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        deleteSubCategory(id);
      }
    });
  };

  const deleteSubCategory = async (id) => {
    try {
      await deleteSubCategoryData(id).then((res) => {
        console.log(res);
        alerShow("Success", "SubCategory Deleted Successfully", "success");
        fetchData();
      });
    } catch (err) {
      console.log(err);
      alerShow("Error!", "An error occurred !", "Error");
    }
  };

  const deleteCategory = async (id) => {
    try {
      await deleteCategoryData(id).then((res) => {
        console.log(res);
        alerShow("Success", "Category Deleted Successfully", "success");
        fetchData();
      });
    } catch (err) {
      console.log(err);
      alerShow("Error!", "An error occurred !", "error");
    }
  };

  const [edit, setEdit] = useState("");
  const handleEdit = (element) => {
    setEdit(element);
    setOpen(true);
  };

  const handleCreate = () => {
    setEdit("");
    setOpen(true);
  };

  const handleCreateSubCategory = () => {
    setsubEdit("");
    setSubOpen(true);
  };

  const [subedit, setsubEdit] = useState("");
  const handleSubEdit = (ele) => {
    setsubEdit(ele);
    setSubOpen(true);
  };
  return (
    <>
      <AddCategory
        open={open}
        setOpen={setOpen}
        fetchData={fetchData}
        edit={edit}
      />
      <AddSubCategory
        openSub={openSub}
        setSubOpen={setSubOpen}
        fetchData={fetchData}
        subedit={subedit}
        Data={Data}
      />
      <div className="page-content">
        <div className="container-fluid">
          <Row>
            <Col lg="12">
              <Card>
                <CardBody className="border-bottom py-2">
                  <div className="d-flex align-items-center">
                    <h5 className="mb-0 card-title flex-grow-1 text-color-gredient">
                      {" "}
                      Category
                    </h5>
                    <div></div>
                    <div className="d-flex align-items-center justify-content-between">
                      <div style={{ marginRight: "8px" }}>
                        <button
                          type="button"
                          className="btn btn-secondary mt-3"
                          onClick={() => handleCreate(true)}
                        >
                          <i className="bx bx-plus"></i> Add Category
                        </button>
                      </div>
                    </div>
                    <div style={{ marginRight: "0px !important" }}>
                      <Link to="#">
                        <button
                          type="button"
                          className="btn btn-secondary mt-3"
                          onClick={() => handleCreateSubCategory(true)}
                        >
                          <i className="bx bx-plus"></i> Add SubCategory
                        </button>
                      </Link>
                    </div>
                  </div>
                </CardBody>

                <CardBody style={{ padding: 16 }}>
                  <div className="table-responsive">
                    <Table
                      id="tech-companies-1"
                      className="table  table-bordered"
                    >
                      <Thead>
                        <Tr>
                          <Th>Sr.No</Th>
                          <Th> Name</Th>
                          <Th> Type</Th>
                          <Th>Actions</Th>
                        </Tr>
                      </Thead>

                      <Tbody>
                        {Data?.map((element, index) => (
                          <React.Fragment key={index}>
                            <tr
                              style={{ backgroundColor: "rgb(236, 229, 229)" }}
                            >
                              <td style={{ paddingLeft: 15 }}>
                                <span className="co-name">{index + 1}.</span>
                              </td>
                              <td>{element.name}</td>
                              <td>
                                {element?.type == "digital" ? (
                                  <Badge color="success" className="me-1">
                                    {" "}
                                    Digital{" "}
                                  </Badge>
                                ) : element?.type == "physical" ? (
                                  <Badge color="primary" className="me-1">
                                    {" "}
                                    Physical{" "}
                                  </Badge>
                                ) : (
                                  <Badge color="info" className="me-1">
                                    {" "}
                                    Subscription{" "}
                                  </Badge>
                                )}
                              </td>
                              <td>
                                <ul className="list-unstyled hstack gap-1 mb-0">
                                  <li>
                                    <Link
                                      className="btn btn-sm btn-primary"
                                      to="#"
                                      onClick={() => handleEdit(element)}
                                    >
                                      <i className="mdi mdi mdi-square-edit-outline" />
                                    </Link>
                                  </li>
                                  <li>
                                    <Link
                                      className="btn btn-sm btn-danger"
                                      to="#"
                                      onClick={() => {
                                        ConfirmDelete(element._id);
                                      }}
                                    >
                                      <i
                                        className="mdi mdi-delete-outline"
                                        id="deletetooltip"
                                      />
                                    </Link>
                                  </li>
                                </ul>
                              </td>
                            </tr>
                            {element.subcategories?.map((ele, i) => (
                              <tr key={`${index}-${i}`}>
                                <td style={{ paddingLeft: 30 }}>
                                  <span className="co-name">
                                    {index + 1}.{i + 1}
                                  </span>
                                </td>
                                <td colSpan={2}>{ele.name}</td>
                                <td>
                                  <ul className="list-unstyled hstack gap-1 mb-0">
                                    <li>
                                      <Link
                                        className="btn btn-sm btn-soft-primary"
                                        to="#"
                                        onClick={() => handleSubEdit(ele)}
                                      >
                                        <i className="mdi mdi mdi-square-edit-outline" />
                                      </Link>
                                    </li>
                                    <li>
                                      <Link
                                        className="btn btn-sm btn-soft-danger"
                                        to="#"
                                        onClick={() => {
                                          ConfirmDelete(ele._id);
                                        }}
                                      >
                                        <i
                                          className="mdi mdi-delete-outline"
                                          id="deletetooltip"
                                        />
                                      </Link>
                                    </li>
                                  </ul>
                                </td>
                              </tr>
                            ))}
                          </React.Fragment>
                        ))}
                      </Tbody>
                    </Table>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      </div>
    </>
  );
}
export default withRouter(Category);
