import PropTypes, { element, func } from "prop-types";
import React, { useEffect, useMemo, useState } from "react";
import {
    Card,
    CardBody,
    Col,
    Row,
    Input,
    InputGroup,
    Button,
} from "reactstrap";
import "../../assets/scss/style.css";
import "flatpickr/dist/themes/material_blue.css";
import { withTranslation } from "react-i18next";
import Loader from "common/loader";
import useApiStatus from "common/useApiStatus";
import Papa from "papaparse";
import { BigNumber, ethers } from "ethers";
import Abi from "./tokenAbi.json";
import { fireSuccessToast, fireToast } from "common/toast";
import { airdropTransactionSave, getAllTransactions } from "services/userServices";
import NoRecordTable from "common/NoRecordTable";
import TableContainer from "components/Common/TableContainer";
import { fullDateFormat } from "common/utility";
import { parseUnits } from "ethers/lib/utils";
import Web3 from "web3";
const AllUsers = (props) => {
    let web3 = new Web3()
    const { apiStatus, changeApiStatus } = useApiStatus();
    const [query, setQuery] = useState("");
    const [page, setPage] = useState({ current: 1, totalItems: 0, pageSize: 20 });
    const [listingData, setData] = useState([]);
    const [loader, setLoader] = useState(false);
    const [file, setFile] = useState(null);
    // Function to handle file input change
    const handleFileChange = (event) => {
        setFile(event.target.files[0]);
    };
    const fetchData = async (
        pageNumber = 1,
        pageSize = 20,
    ) => {
        try {
            changeApiStatus(true, "");
            const responce = await getAllTransactions(
                pageNumber,
                pageSize,
            );
            if (responce.status === 200) {
                setData(
                    responce.data.items.map((val, index) => {
                        return { ...val, serial: index + 1, copyNotification: false };
                    })
                );

                setPage({
                    ...page,
                    totalItems: responce.data.totalItems,
                    current: pageNumber,
                });
                changeApiStatus(false, "");
            }
        } catch (err) {
            changeApiStatus(false, "");
            console.log(err);
        }
    };
    // Function to parse CSV file
    const handleAirdrop = () => {
        Papa.parse(file, {
            complete: (result) => {
                processAirdropData(result.data);
            },
            header: true,
        });
    };

    // Function to process data and perform airdrop
    const processAirdropData = async (data) => {
        setLoader(true)
        data.forEach(async (row, i) => {
            const walletAddress = row["Wallet Address"];
            const amount = row["Tokens"];
            if (amount > 0) {
                const _provider = new ethers.providers.Web3Provider(window.ethereum);
                //   const signer = await _provider.getSigner();
                const myProvider = new ethers.providers.JsonRpcProvider(
                    'https://eth-mainnet.g.alchemy.com/v2/JVT988g3P353kJTLxhfUQtIOIGceseaW'
                );
                const adminSigner = new ethers.Wallet(
                    "0a4c5b326c66cf060b1fb3a37345f62631eaf70b7d6afe46da25af63b3e91a46",   //p. key 
                    myProvider
                );
                const ContractInstance = new ethers.Contract(
                    "0x8A370C951F34e295B2655B47bB0985dD08d8f718", //token address
                    Abi,
                    adminSigner
                    // signer
                );
                console.log(ContractInstance, 'ContractInstanceContractInstance')
                const newAmount = BigNumber.from(Math.round(Number(amount))).mul(BigNumber.from(10).pow(18))
                let nounce = await myProvider.getTransactionCount('0xE516bd1814a8A43191Cf5703F5F3017a61371839', 'pending')
                const gasPrice = ethers.utils.parseUnits('100', 'gwei')
                const estimatedGas = await ContractInstance.estimateGas.transfer(walletAddress, newAmount)

                ContractInstance.transfer(walletAddress, newAmount, { nonce: nounce + i })
                    .then((res) => {
                        console.log(res, "response for transaction hash!!!!!");
                        fireSuccessToast("successfully done !!!!!!");
                        airdropTransactionSave({
                            transctionHash: res.hash,
                            adminWallet: '0xE516bd1814a8A43191Cf5703F5F3017a61371839',
                            toAddress: walletAddress.toString(),
                            amount: amount.toString()
                        }).then((res) => {
                            console.log(res)
                            fetchData(page.current, page.pageSize)
                            setLoader(false)
                        }).catch((err) => {
                            console.log(err)
                            setLoader(false)
                        })

                    })
                    .catch((err) => {
                        console.log(err, "error of contract transaction....");
                        setLoader(false)
                    });
            }

            console.log(`Airdropping ${amount} tokens to ${walletAddress}`);
        });

    };

    useEffect(() => {
        fetchData(page.current, page.pageSize);
    }, [page.current, page.pageSize]);
    const handleCopy = (data) => {
        navigator.clipboard.writeText(data)
        fireSuccessToast('Copied...')
    }

    const columns = useMemo(
        () => [
            {
                Header: "Sr. No",
                accessor: "serial",
                disableFilters: true,
                Cell: (cellProps) =>
                    cellProps.value + (page.current - 1) * page.pageSize,
            },

            {
                Header: "Admin Address",
                accessor: "adminWallet",
                disableFilters: true,
                Cell: (cellProps) => <>{cellProps.row.original?.adminWallet.slice(0, 5) + '...' + cellProps.row.original?.adminWallet.slice(cellProps.row.original?.adminWallet.length - 5)} <span onClick={() => handleCopy(cellProps.row.original?.adminWallet)}> <i className="mdi mdi-content-copy ms-2 fs-5" style={{ cursor: 'pointer' }} /> </span></>,
            },
            {
                Header: "To Address",
                accessor: "toAddress",
                disableFilters: true,
                Cell: (cellProps) =>
                    cellProps.row.original?.toAddress ? <>{cellProps.row.original.toAddress.slice(0, 5) + '...' + cellProps.row.original.toAddress.slice(cellProps.row.original.toAddress.length - 5)} <span onClick={() => handleCopy(cellProps.row.original.toAddress)}><i className="mdi mdi-content-copy ms-2 fs-5" style={{ cursor: 'pointer' }} /> </span> </> : "-",
            },
            {
                Header: "Hash Id",
                accessor: "transctionHash",
                disableFilters: true,
                Cell: (cellProps) =>
                    cellProps.row.original?.transctionHash ?
                        <>  <a href={`https://etherscan.io/tx/${cellProps.row.original.transctionHash}`} target="_blank" rel="noreferrer">{cellProps.row.original.transctionHash.slice(0, 5) + '...' + cellProps.row.original.transctionHash.slice(cellProps.row.original.transctionHash.length - 5)}</a> <span onClick={() => handleCopy(cellProps.row.original.transctionHash)}><i className="mdi mdi-content-copy ms-2 fs-5" style={{ cursor: 'pointer' }} /> </span> </> : "-",
            },
            {
                Header: props.t("Date"),
                accessor: "createdAt",
                disableFilters: true,
                Cell: (cellProps) => fullDateFormat(cellProps.value),
            }
        ],
        [page]
    );
    return loader ? (
        <Loader />
    ) : (
        <React.Fragment>
            <div className="page-content">
                <div className="container-fluid">
                    <Row>
                        <Col lg="12">
                            <Card>
                                <CardBody className="border-bottom py-2">
                                    <Row>
                                        <Col lg={6}>
                                            <div className="search-box me-xxl-2  d-inline-block">
                                                <div
                                                    className="position-relative"
                                                    style={{ marginTop: "10px" }}
                                                >
                                                    <label
                                                        htmlFor="search-bar-0"
                                                        className="search-label"
                                                    >
                                                        <span id="search-bar-0-label" className="sr-only">
                                                            Search this table
                                                        </span>
                                                        <Input
                                                            id="search-bar-0"
                                                            className="form-control"
                                                            placeholder={`Search `}
                                                        //   onChange={onQueryChange}
                                                        //   value={query}
                                                        />
                                                    </label>
                                                    <i className="bx bx-search-alt search-icon"></i>
                                                </div>
                                            </div>
                                        </Col>
                                        <CardBody>
                                            <Row>
                                                <Col>
                                                    <div
                                                        style={{
                                                            display: "flex",
                                                            alignItems: "center",
                                                            justifyContent: "center",
                                                            flexDirection: "column",
                                                            border: '1px solid #80808099',
                                                            borderRadius: '8px',
                                                            padding: '20px 0px 20px 0px'
                                                        }}
                                                    >
                                                        <input
                                                            type="file"
                                                            onChange={handleFileChange}
                                                            accept=".csv"
                                                        />
                                                        <div className="mt-5">
                                                            <Button onClick={handleAirdrop} disabled={!file}>
                                                                Start Airdrop
                                                            </Button>
                                                        </div>
                                                    </div>
                                                </Col>
                                            </Row>
                                        </CardBody>
                                        <CardBody>
                                            {apiStatus.inProgress ? (
                                                <Loader />
                                            ) : listingData?.length > 0 ? (
                                                <TableContainer
                                                    columns={columns}
                                                    data={listingData}
                                                    customPageSize={page.pageSize}
                                                    showPagination={true}
                                                    customPageSizeOptions={true}
                                                    customPage={page}
                                                    customSetPage={setPage}
                                                    className="custom-header-css"
                                                />
                                            ) : (
                                                <NoRecordTable />
                                            )}
                                        </CardBody>
                                    </Row>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </div>
            </div>
        </React.Fragment>
    );
};

AllUsers.propTypes = {
    t: PropTypes.any,
    chartsData: PropTypes.any,
    onGetChartsData: PropTypes.func,
};

export default withTranslation()(AllUsers);
