import React, { useContext, useEffect, useState } from "react"
import Button from "react-bootstrap/Button"
import { Modal, Form } from "react-bootstrap"
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { withRouter, Link } from "react-router-dom"
import {
  Card,
  CardBody,
  Col,
  Row,
  Container,
  Badge,
  CardTitle,
  FormGroup,
  Input,
  InputGroup,
} from "reactstrap"
import { Table, Thead, Tbody, Tr, Th, Td } from "react-super-responsive-table"
import { LoaderContext } from "context/ContextProvider"
import axios from "axios"
import data from "./data"
import { element } from "prop-types"
import { apiHeader, apiRoute } from "helpers/api_helper";
import { addNewFaq, deleteFaqData, getFaqDetail, updateNewFaq } from "services/userServices";
import { alerShow } from "admin/commonFunction";
import Loader from "common/loader";
function Faq() {
  const {
    Add,
    Edit,
    showEdit,
    handleShow,
    handelShow1,
    handleClose,
    setloading,
  } = useContext(LoaderContext)

  const [loading, loadingSet] = useState(false);
  const [Data, setdata] = useState()
  const [edit, setEdit] = useState({
    id: "",
    Question: "",
    Answer: "",
  })
  console.log();
  const handleFaq = async (values) => {

    loadingSet(true)
    try {
      await addNewFaq({
        question: values.question,
        answers: values.answer
      }).then(res => {
        console.log(res)
        loadingSet(false)
        alerShow("Success", "FAQ Added Successfully", "success");
        handleClose()
        fetchData()
      })
    } catch (err) {
      console.log(err)
      loadingSet(false)
      alerShow("Error!", "An error occurred !", "Error");
    }
  }

  const editFAQ = (id, Question, Answer) => {
    handleShow()
    setEdit({ id, Question, Answer })
  }

  const updateFAQ = async (e) => {
    e.preventDefault()
    let bodyData = {
      faqID: edit.id,
      question: edit.Question,
      answer: edit.Answer
    }
    loadingSet(true)
    try {

      await updateNewFaq({
        question: bodyData.question,
        answers: bodyData.answer,
        id: edit.id
      }).then(res => {
        console.log(res)
        loadingSet(false)
        handleClose()
        alerShow("Success", "FAQ Updated Successfully", "success");
        fetchData()
      })
    } catch (err) {
      console.log(err)
      loadingSet(false)
      alerShow("Error!", "An error occurred !", "Error");
    }
  }

  const fetchData = async () => {
    try {
      loadingSet(true)
      await getFaqDetail().then(res => {
        console.log(res.data.items, "hfghg")
        setdata(res.data.items)
        loadingSet(false)
      })
    } catch (err) {
      loadingSet(false)
      console.log(err)
    }
  }

  useEffect(() => {
    fetchData()
  }, [])



  const deleteFAQ = async (faq_id) => {
    try {

      loadingSet(true)
      await deleteFaqData(faq_id).then(res => {
        console.log(res)
        alerShow("Success", "FAQ Deleted Successfully", "success");
        loadingSet(false)
        fetchData()
      })
    } catch (err) {
      console.log(err)
      loadingSet(false)
      alerShow("Error!", "An error occurred !", "Error");
    }
  }

  const ConfirmDelete = id => {
    // let URL = BASE_URL + 'user/delete/' + id;
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then(result => {
      if (result.isConfirmed) {
        deleteFAQ(id)
      }
    })
  }



  const formik = useFormik({
    initialValues: {
      question: '',
      answer: ''
    },
    validationSchema: Yup.object({
      question: Yup.string()
        .required('question is required'),
      answer: Yup.string()
        .required('answer is required')
    }),
    onSubmit: values => {
      handleFaq(values);
    },
  });
  useEffect(() => {
    formik.resetForm();
  }, [Add])

  return loading ? (
    <Loader />
  ) : (

    <>
      <div className="page-content">
        <div className="container-fluid">
          <Row>
            <Col lg="12">
              <Card>
                <CardBody className="border-bottom py-2">
                  <div className="d-flex align-items-center">
                    <h5 className="mb-0 card-title flex-grow-1 text-color-gredient">
                      {" "}
                      Frequently Asked Questions
                    </h5>
                    <div style={{ marginRight: '0px !important' }}>
                      <button
                        type="button"
                        className="btn btn-secondary mt-3"
                        onClick={handelShow1}
                      >
                        <i className="bx bx-plus"></i> Add FAQ
                      </button>
                    </div>
                  </div>
                </CardBody>

                <CardBody style={{ padding: 16 }}>
                  <div className="table-responsive">
                    <Table
                      id="tech-companies-1"
                      className="table table-striped table-bordered"
                    >
                      <Thead>
                        <Tr>
                          <Th>Sr.No</Th>
                          <Th> Question</Th>
                          <Th> Answer </Th>
                          <Th> Action </Th>
                        </Tr>
                      </Thead>
                      <Tbody>
                        {Data?.map((element, index) => {
                          return (
                            <tr key={index}>
                              <td style={{ padding: 35 }}>
                                <span className="co-name"> {index + 1}</span>
                              </td>
                              <td>{element.question}</td>
                              <td>{element.answers}</td>
                              <td>
                                <ul className="list-unstyled hstack gap-1 mb-0">
                                  <li>
                                    <Link
                                      onClick={() => {
                                        editFAQ(
                                          element._id,
                                          element.question,
                                          element.answers
                                        )
                                      }}
                                      className="btn btn-sm btn-soft-primary"
                                    >
                                      <i className="mdi mdi-pencil" />
                                    </Link>
                                  </li>
                                  <li>
                                    <Link
                                      to="#"
                                      className="btn btn-sm btn-soft-danger"
                                      onClick={() => {
                                        ConfirmDelete(element._id)
                                      }}
                                    >
                                      <i
                                        className="mdi mdi-delete-outline"
                                        id="deletetooltip"
                                      />
                                    </Link>
                                  </li>
                                </ul>
                              </td>
                            </tr>
                          )
                        })}
                      </Tbody>
                    </Table>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      </div>
      <Modal
        size='lg'
        show={Add}
        onHide={handleClose}
        aria-labelledby="contained-modal-title-vcenter"
        backdrop="static"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>Add FAQ</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form onSubmit={formik.handleSubmit}>
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
              <Form.Label>Question</Form.Label>
              <Form.Control
                type="text"
                name="question"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.question}
                placeholder=""
                autoFocus='true'
              />
              {formik.touched.question && formik.errors.question ? (
                <div style={{ color: 'red' }}>{formik.errors.question}</div>
              ) : null}
            </Form.Group>
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
              <Form.Label>Answer</Form.Label>
              <Form.Control
                as="textarea"
                name="answer"
                rows="8"

                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.answer}
                placeholder=""
                aria-label="With textarea"

              />
              {formik.touched.answer && formik.errors.answer ? (
                <div style={{ color: 'red' }}>{formik.errors.answer}</div>
              ) : null}

            </Form.Group>
            <Button variant="secondary" onClick={handleClose}>
              Close
            </Button>
            <Button type="submit" variant="primary" style={{ marginLeft: 10 }}>
              ADD
            </Button>
          </Form>
        </Modal.Body>
        <Modal.Footer></Modal.Footer>
      </Modal>

      <Modal
        size="lg"
        show={Edit}
        onHide={handleClose}
        aria-labelledby="contained-modal-title-vcenter"
        backdrop="static"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>Edit FAQ</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form onSubmit={updateFAQ}>
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
              <Form.Label>Question</Form.Label>
              <Form.Control
                type="text"
                value={edit ? edit.Question : ""}
                onChange={e => {
                  setEdit({ ...edit, Question: e.target.value })
                }}
                name="question"
                placeholder=""
                autoFocus='true'
                controlId="exampleForm.ControlTextarea1"
              />
            </Form.Group>
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
              <Form.Label>Answer</Form.Label>
              <Form.Control
                as="textarea"
                value={edit ? edit.Answer : ""}
                onChange={e => {
                  setEdit({ ...edit, Answer: e.target.value })
                }}
                name="answer"
                aria-label="With textarea"
                rows={8}
              />
            </Form.Group>
            <Button variant="secondary" onClick={handleClose}>
              Close
            </Button>
            <Button variant="primary" type="submit" style={{ marginLeft: 10 }}>
              UPDATE
            </Button>
          </Form>
        </Modal.Body>
        <Modal.Footer></Modal.Footer>
      </Modal>
    </>
  )
}
export default withRouter(Faq)
