import React, { useContext, useEffect, useState } from "react";
import Button from "react-bootstrap/Button";
import { Modal, Form } from "react-bootstrap";
import { useFormik } from "formik";
import * as Yup from "yup";
import { withRouter, Link } from "react-router-dom";
import { Card, CardBody, Col, Row } from "reactstrap";
import { Table, Thead, Tbody, Tr, Th, Td } from "react-super-responsive-table";
import { LoaderContext } from "context/ContextProvider";
import {
  deletePlanData,
  addNewDao,
  getAllProposal,
  updateDaoStatus,
  deleteDao,
} from "services/userServices";
import { alerShow } from "admin/commonFunction";
import JoditEditor from "jodit-react";
import moment from "moment";
import "../../style/global.css";
function Plan() {
  const {
    Add,
    Edit,
    showEdit,
    handleShow,
    handelShow1,
    handleClose,
    setloading,
  } = useContext(LoaderContext);
  const [Data, setdata] = useState();
  const [edit, setEdit] = useState({
    id: "",
    Question: "",
    Answer: "",
  });
  const handleSubmit = async (values) => {
    setloading(true);
    try {
      await addNewDao({
        title: values.title,
        description: values.description,
        startDate: values.startDate,
        endDate: values.endDate,
        maxHommiesRequired: values.maxHommiesRequired,
        maxRequired: values.maxRequired
      }).then((res) => {
        console.log(res);
        setloading(false);
        alerShow("Success", "New Proposal Added Successfully", "success");
        handleClose();
        fetchData();
      });
    } catch (err) {
      console.log(err);
      setloading(false);
      alerShow("Error!", "An error occurred !", "Error");
    }
  };

  const editPlan = (
    id,
    title,
    description,
    startDate,
    endDate,
    maxHommiesRequired,
    maxRequired
  ) => {
    handleShow();
    setEdit({ id, title, description, startDate, endDate, maxHommiesRequired, maxRequired });
  };

  const updatePlan = async (e) => {
    e.preventDefault();
    let bodyData = {
      id: edit.id,
      title: edit.title,
      description: edit.description,
      startDate: edit.startDate,
      endDate: edit.endDate,
      maxHommiesRequired: edit.maxHommiesRequired,
      maxRequired: edit?.maxRequired
    };
    setloading(true);
    try {
      await addNewDao({
        title: bodyData.title,
        description: bodyData.description,
        startDate: bodyData.startDate,
        endDate: bodyData.endDate,
        maxRequired: bodyData?.maxRequired,
        maxHommiesRequired: bodyData.maxHommiesRequired,
        id: edit.id,
      }).then((res) => {
        console.log(res);
        setloading(false);
        handleClose();
        alerShow("Success", "Proposal Updated Successfully", "success");
        fetchData();
      });
    } catch (err) {
      console.log(err);
      setloading(false);
      alerShow("Error!", "An error occurred !", "Error");
    }
  };

  const fetchData = async () => {
    try {
      await getAllProposal().then((res) => {
        console.log(res.data, "hfghg");
        setdata(res.data);
      });
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const deleteFAQ = async (faq_id) => {
    try {
      await deleteDao(faq_id).then((res) => {
        console.log(res);
        alerShow("Success", "Proposal Deleted Successfully", "success");
        fetchData();
      });
    } catch (err) {
      console.log(err);
      alerShow("Error!", "An error occurred !", "Error");
    }
  };

  const ConfirmDelete = (id) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        deleteFAQ(id);
      }
    });
  };

  const formik = useFormik({
    initialValues: {
      title: "",
      description: "",
      startDate: "",
      endDate: "",
      maxHommiesRequired: "",
      maxRequired:""
    },
    validationSchema: Yup.object({
      title: Yup.string().required("Tile is required"),
      description: Yup.string().required("Description is required"),
      startDate: Yup.date().required("Start Date is required"),
      endDate: Yup.date().required(" End Date is required"),
      maxRequired: Yup.number()
    .required("Required")
    .min(1, "Must be greater than 0"),
  maxHommiesRequired: Yup.number()
    .required("Required")
    .min(1, "Must be greater than 0"),
    }),
    onSubmit: (values) => {
      handleSubmit(values);
    },
  });
  useEffect(() => {
    formik.resetForm();
  }, [Add]);

  const  handleChange = (id, item) => {
console.log(item, id,"testing")
updateDaoStatus({
  status: item,
  daoId: id
}).then((res)=>{
  alerShow("Success", "Status Successfully updated", "success");
  fetchData();
}).catch((err)=>{
  alerShow("Error", err?.message, "error");
})
  }

  return (
    <>
      <div className="page-content">
        <div className="container-fluid">
          <Row>
            <Col lg="12">
              <Card>
                <CardBody className="border-bottom py-2">
                  <div className="d-flex align-items-center">
                    <h5 className="mb-0 card-title flex-grow-1 text-color-gredient">
                      {" "}
                      DAO Proposal
                    </h5>
                    <div style={{ marginRight: "0px !important" }}>
                      <button
                        type="button"
                        className="btn btn-secondary mt-3"
                        onClick={handelShow1}
                      >
                        <i className="bx bx-plus"></i> Add Proposal
                      </button>
                    </div>
                  </div>
                </CardBody>

                <CardBody style={{ padding: 16 }}>
                  <div className="table-responsive">
                    <Table
                      id="tech-companies-1"
                      className="table table-striped table-bordered"
                    >
                      <Thead>
                        <Tr>
                          <Th>Sr.No</Th>
                          <Th> Title </Th>
                          <Th> Start Date </Th>
                          <Th> End Date </Th>
                          <Th> Min Hommies Required </Th>
                          <Th>Max User</Th>
                          <Th>Total Votes</Th>
                          <Th> Status </Th>
                          <Th>Action</Th>
                        </Tr>
                      </Thead>
                      <Tbody>
                        {Data?.map((element, index) => {
                          return (
                            <tr key={index}>
                              <td style={{ paddingLeft: 15 }}>
                                <span className="co-name"> {index + 1}.</span>
                              </td>
                              <td className="truncate">{element.element.title}</td>
                              {/* <td className="truncate">
                                {element.description}
                              </td> */}
                              <td>{moment(element?.element.startDate).format("LL")}</td>
                              <td>{moment(element?.element.endDate).format("LL")}</td>
                              <td>{element?.element.maxHommiesRequired}</td>
                              <td>{element?.element.maxRequired}</td>
                              <td>
                                Up-Votes : {element?.positiveCount}<br/>
                                Down-Votes : {element?.negativeCount}
                              </td>
                              <td>
                                {" "}
                                <select
                                  className="form-control select2 mb-xxl-0"
                                  style={{ width: "165px" }}
                                  onChange={(e)=>handleChange(element?.element._id,e.target.value)}
                                >
                                  <option value={element.element.status}>
                                    {element.element.status}
                                  </option>
                                  <option value="PENDING">PENDING</option>
                                  <option value="PROGRESS">PROGRESS</option>
                                  <option value="REJECTED">REJECTED</option>
                                  <option value="COMPLETED">COMPLETED</option>
                                </select>
                              </td>
                              <td>
                                <ul className="list-unstyled hstack gap-1 mb-0">
                                  <li>
                                    <Link
                                      onClick={() => {
                                        editPlan(
                                          element.element._id,
                                          element.element.title,
                                          element.element.description,
                                          element.element.startDate,
                                          element.element.endDate,
                                          element.element.maxHommiesRequired,
                                          element?.element.maxRequired
                                        );
                                      }}
                                      className="btn btn-sm btn-soft-primary"
                                    >
                                      <i className="mdi mdi-pencil" />
                                    </Link>
                                  </li>
                                  <li>
                                    <Link
                                      to="#"
                                      className="btn btn-sm btn-soft-danger"
                                      onClick={() => {
                                        ConfirmDelete(element?.element?._id);
                                      }}
                                    >
                                      <i
                                        className="mdi mdi-delete-outline"
                                        id="deletetooltip"
                                      />
                                    </Link>
                                  </li>
                                </ul>
                              </td>
                            </tr>
                          );
                        })}
                      </Tbody>
                    </Table>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      </div>
      <Modal
        size="lg"
        show={Add}
        onHide={handleClose}
        aria-labelledby="contained-modal-title-vcenter"
        backdrop="static"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>Add Proposal</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form onSubmit={formik.handleSubmit}>
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
              <Form.Label>Tittle</Form.Label>
              <Form.Control
                type="text"
                name="title"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.title}
                placeholder=""
                autoFocus="true"
              />
              {formik.touched.title && formik.errors.title ? (
                <div style={{ color: "red" }}>{formik.errors.title}</div>
              ) : null}
            </Form.Group>
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
              <Modal.Title>Description</Modal.Title>
              <JoditEditor
                name="description"
                id="description"
                rows="5"
                tabIndex={1} // tabIndex of textarea
                onChange={(newContent) =>
                  formik.setFieldValue("description", newContent)
                }
                value={formik.values.description}
              />
              {formik.touched.content && formik.errors.content ? (
                <div style={{ color: "red" }}>{formik.errors.content}</div>
              ) : null}
            </Form.Group>
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
              <Form.Label>Max User Required</Form.Label>
              <Form.Control
                type="number"
                name="maxRequired"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.maxRequired}
                placeholder=""
                autoFocus="true"
                min={0}
              />
              {formik.touched.maxRequired &&
              formik.errors.maxRequired ? (
                <div style={{ color: "red" }}>
                  {formik.errors.maxRequired}
                </div>
              ) : null}
            </Form.Group>
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
              <Form.Label>Max Hommies Required</Form.Label>
              <Form.Control
                type="number"
                name="maxHommiesRequired"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.maxHommiesRequired}
                placeholder=""
                autoFocus="true"
                min={0}
              />
              {formik.touched.maxHommiesRequired &&
              formik.errors.maxHommiesRequired ? (
                <div style={{ color: "red" }}>
                  {formik.errors.maxHommiesRequired}
                </div>
              ) : null}
            </Form.Group>
            <Row>
              <Col md="6">
                <Form.Group
                  className="mb-3"
                  controlId="exampleForm.ControlInput1"
                >
                  <Form.Label>Start Date :</Form.Label>
                  <Form.Control
                    type="date"
                    name="startDate"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.startDate}
                    min={moment().format("YYYY-MM-DD")}
                    placeholder=""
                  />
                  {formik.touched.startDate && formik.errors.startDate ? (
                    <div style={{ color: "red" }}>
                      {formik.errors.startDate}
                    </div>
                  ) : null}
                </Form.Group>
              </Col>
              <Col md="6">
                <Form.Group
                  className="mb-3"
                  controlId="exampleForm.ControlInput1"
                >
                  <Form.Label>End Date :</Form.Label>
                  <Form.Control
                    type="date"
                    name="endDate"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.endDate}
                     min={moment().add(1, 'days').format("YYYY-MM-DD")}
                    placeholder=""
                  />
                  {formik.touched.endDate && formik.errors.endDate ? (
                    <div style={{ color: "red" }}>{formik.errors.endDate}</div>
                  ) : null}
                </Form.Group>
              </Col>
            </Row>

            <Button variant="secondary" onClick={handleClose}>
              Close
            </Button>
            <Button type="submit" variant="primary" style={{ marginLeft: 10 }}>
              ADD
            </Button>
          </Form>
        </Modal.Body>
        <Modal.Footer></Modal.Footer>
      </Modal>

      <Modal
        size="lg"
        show={Edit}
        onHide={handleClose}
        aria-labelledby="contained-modal-title-vcenter"
        backdrop="static"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>Edit Proposal</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form onSubmit={updatePlan}>
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
              <Form.Label>Title</Form.Label>
              <Form.Control
                type="text"
                value={edit ? edit.title : ""}
                onChange={(e) => {
                  setEdit({ ...edit, title: e.target.value });
                }}
                name="title"
                placeholder=""
                autoFocus="true"
                controlId="exampleForm.ControlTextarea1"
              />
            </Form.Group>
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
              <Form.Label>Description</Form.Label>
              <JoditEditor
                name="description"
                id="description"
                rows="5"
                tabIndex={1} // tabIndex of textarea
                onChange={(newContent) =>
                  setEdit({ ...edit, description: newContent })
                }
                value={edit ? edit?.description : ""}
              />
            </Form.Group>
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
              <Form.Label>Max User Required</Form.Label>
              <Form.Control
                type="number"
                min={0}
                value={edit ? edit.maxRequired : ""}
                onChange={(e) => {
                  setEdit({ ...edit, maxRequired: e.target.value });
                }}
                name="maxRequired"
                placeholder=""
                autoFocus="true"
                controlId="exampleForm.ControlTextarea1"
              />
            </Form.Group>
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
              <Form.Label>Max Hommies Required</Form.Label>
              <Form.Control
                type="number"
                min={0}
                value={edit ? edit.maxHommiesRequired : ""}
                onChange={(e) => {
                  setEdit({ ...edit, maxHommiesRequired: e.target.value });
                }}
                name="maxHommiesRequired"
                placeholder=""
                autoFocus="true"
                controlId="exampleForm.ControlTextarea1"
              />
            </Form.Group>
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
              <Form.Label>Start Date </Form.Label>
              <Form.Control
                type="date"
                value={edit ? moment(edit.startDate).format("YYYY-MM-DD") : ""}
                onChange={(e) => {
                  setEdit({ ...edit, startDate: e.target.value });
                }}
                name="startDate"
                  min={moment().format("YYYY-MM-DD")}
              />
            </Form.Group>
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
              <Form.Label>End Date</Form.Label>
              <Form.Control
                type="date"
                value={edit ? moment(edit.endDate).format("YYYY-MM-DD") : ""}
                onChange={(e) => {
                  setEdit({ ...edit, endDate: e.target.value });
                }}
                name="endDate"
                min={moment().format("YYYY-MM-DD")}
              />
            </Form.Group>

            <Button variant="secondary" onClick={handleClose}>
              Close
            </Button>
            <Button variant="primary" type="submit" style={{ marginLeft: 10 }}>
              Update
            </Button>
          </Form>
        </Modal.Body>
        <Modal.Footer></Modal.Footer>
      </Modal>
    </>
  );
}
export default withRouter(Plan);
