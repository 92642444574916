import PropTypes, { element, func } from "prop-types";
import React, { useContext, useEffect, useState, useRef } from "react";
import { Link } from "react-router-dom";
import { Card, CardBody, Col, Row, Label } from "reactstrap";
import { Table, Thead, Tbody, Tr, Th, Td } from "react-super-responsive-table";
import Dropzone from "react-dropzone";
import cloud from "../../assets/images/cloud-file-download.svg";
import Button from "react-bootstrap/Button";
import { useFormik } from "formik";
import * as Yup from "yup";
import { createNewProject } from "services/helper";
import { useHistory, useLocation } from "react-router";
import { Modal, Form } from "react-bootstrap";
import "../../assets/scss/style.css";
import "flatpickr/dist/themes/material_blue.css";
import { withTranslation } from "react-i18next";
import Loader from "common/loader";
import { fireToast } from "common/toast";
import JoditEditor from "jodit-react";

const addProject = (props) => {
  document.title = "Projects";
  const history = useHistory();
  const location = useLocation();

  const [loader, setLoader] = useState(false);
  const stateProps = location.state;
  const id = stateProps?._id ? stateProps._id : null;

  const [image, setImage] = useState({
    blob: null,
    src: stateProps?.image ?? "",
  });
  const formik = useFormik({
    initialValues: {
      title: stateProps?.title ?? "",
      contractAddress: stateProps?.contractAddress ?? "",
      coinMarketCap: stateProps?.coinMarketCap ?? "",
      whitePaper: stateProps?.whitePaper ?? "",
      twitter: stateProps?.twitter ?? "",
      telegram: stateProps?.telegram ?? "",
      website: stateProps?.website ?? "",
      longDesc: stateProps?.longDesc ?? "",
      shortDesc: stateProps?.shortDesc ?? "",
    },
    validationSchema: Yup.object({
      title: Yup.string().required("Title is required"),
      contractAddress: Yup.string().required("Contract address is required"),
      longDesc: Yup.string().required("Long description is required"),
      shortDesc: Yup.string()
        .max(100, "Too Long")
        .required("Short description is required"),
    }),
    onSubmit: async (values, { resetForm }) => {
      if (!image.src) {
        fireToast("error", "Image File is Required");
      } else {
        try {
          setLoader(true);
          const response = await createNewProject(
            id
              ? {
                  ...values,
                  id: id,
                  image: image.blob ? image.blob : null,
                }
              : {
                  ...values,
                  image: image.blob ? image.blob : null,
                }
          );
          if (response.status == 200) {
            fireToast("success", response.message);
            history.push("/admin/project-list");
            resetForm();
            setLoader(false);
          }
        } catch (error) {
          fireToast("error", error.response.data.error);
          setLoader(false);
        }
      }
    },
  });

  const handleImageChange = (files) => {
    const extn = ["image/jpg", "image/png", "image/jpeg"];
    const [file] = files;
    if (file && extn.includes(file.type)) {
      if (file.size <= 104857600) {
        setImage({ blob: file, src: window.URL.createObjectURL(file) });
      } else {
        showToast("error", props.t("File Too Large (100 mb Allowed)"));
      }
    } else {
      showToast("error", props.t("Valid Image File"));
    }
  };

  const uploadRef = useRef(null);

  return loader ? (
    <Loader />
  ) : (
    <React.Fragment>
      <div className="page-content">
        <div className="container-fluid">
          <Row>
            <Col lg="12">
              <Card className="p-5">
                <CardBody className="border-bottom py-2">
                  <div>
                    <h4 className="text-center mb-3">Add New Project</h4>
                    <Form onSubmit={formik.handleSubmit}>
                      <Form.Group
                        className="mb-3"
                        controlId="exampleForm.ControlInput1"
                      >
                        <Form.Label>Title</Form.Label>
                        <Form.Control
                          type="text"
                          name="title"
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          value={formik.values.title}
                          placeholder=""
                          autoFocus="true"
                        />
                        {formik.touched.title && formik.errors.title ? (
                          <div style={{ color: "red" }}>
                            {formik.errors.title}
                          </div>
                        ) : null}
                      </Form.Group>
                      <div className="row">
                        <div className="col-6">
                          <Form.Group
                            className="mb-3"
                            controlId="exampleForm.ControlInput1"
                          >
                            <Form.Label>Contract Address</Form.Label>
                            <Form.Control
                              type="text"
                              name="contractAddress"
                              onChange={formik.handleChange}
                              onBlur={formik.handleBlur}
                              value={formik.values.contractAddress}
                              placeholder=""
                              autoFocus="true"
                            />
                            {formik.touched.contractAddress &&
                            formik.errors.contractAddress ? (
                              <div style={{ color: "red" }}>
                                {formik.errors.contractAddress}
                              </div>
                            ) : null}
                          </Form.Group>
                        </div>
                        <div className="col-6">
                          <Form.Group
                            className="mb-3"
                            controlId="exampleForm.ControlInput1"
                          >
                            <Form.Label>Coin Market Cap Link</Form.Label>
                            <Form.Control
                              type="text"
                              name="coinMarketCap"
                              onChange={formik.handleChange}
                              onBlur={formik.handleBlur}
                              value={formik.values.coinMarketCap}
                              placeholder=""
                              autoFocus="true"
                            />
                            {formik.touched.coinMarketCap &&
                            formik.errors.coinMarketCap ? (
                              <div style={{ color: "red" }}>
                                {formik.errors.coinMarketCap}
                              </div>
                            ) : null}
                          </Form.Group>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-6">
                          <Form.Group
                            className="mb-3"
                            controlId="exampleForm.ControlInput1"
                          >
                            <Form.Label>Website Link</Form.Label>
                            <Form.Control
                              type="text"
                              name="website"
                              onChange={formik.handleChange}
                              onBlur={formik.handleBlur}
                              value={formik.values.website}
                              placeholder=""
                              autoFocus="true"
                            />
                            {formik.touched.website && formik.errors.website ? (
                              <div style={{ color: "red" }}>
                                {formik.errors.website}
                              </div>
                            ) : null}
                          </Form.Group>
                        </div>
                        <div className="col-6">
                          <Form.Group
                            className="mb-3"
                            controlId="exampleForm.ControlInput1"
                          >
                            <Form.Label>Twitter Link</Form.Label>
                            <Form.Control
                              type="text"
                              name="twitter"
                              onChange={formik.handleChange}
                              onBlur={formik.handleBlur}
                              value={formik.values.twitter}
                              placeholder=""
                              autoFocus="true"
                            />
                            {formik.touched.twitter && formik.errors.twitter ? (
                              <div style={{ color: "red" }}>
                                {formik.errors.twitter}
                              </div>
                            ) : null}
                          </Form.Group>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-6">
                          <Form.Group
                            className="mb-3"
                            controlId="exampleForm.ControlInput1"
                          >
                            <Form.Label>Telegram Link</Form.Label>
                            <Form.Control
                              type="text"
                              name="telegram"
                              onChange={formik.handleChange}
                              onBlur={formik.handleBlur}
                              value={formik.values.telegram}
                              placeholder=""
                              autoFocus="true"
                            />
                            {formik.touched.telegram &&
                            formik.errors.telegram ? (
                              <div style={{ color: "red" }}>
                                {formik.errors.telegram}
                              </div>
                            ) : null}
                          </Form.Group>
                        </div>
                        <div className="col-6">
                          <Form.Group
                            className="mb-3"
                            controlId="exampleForm.ControlInput1"
                          >
                            <Form.Label>White Paper Link</Form.Label>
                            <Form.Control
                              type="text"
                              name="whitePaper"
                              onChange={formik.handleChange}
                              onBlur={formik.handleBlur}
                              value={formik.values.whitePaper}
                              placeholder=""
                              autoFocus="true"
                            />
                            {formik.touched.whitePaper &&
                            formik.errors.whitePaper ? (
                              <div style={{ color: "red" }}>
                                {formik.errors.whitePaper}
                              </div>
                            ) : null}
                          </Form.Group>
                        </div>
                      </div>

                      <Form.Group
                        className="mb-3"
                        controlId="exampleForm.ControlInput1"
                      >
                        <Form.Label>Short Description</Form.Label>
                        <Form.Control
                          as="textarea"
                          name="shortDesc"
                          rows="2"
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          value={formik.values.shortDesc}
                          placeholder=""
                          aria-label="With textarea"
                        />
                        {formik.touched.shortDesc && formik.errors.shortDesc ? (
                          <div style={{ color: "red" }}>
                            {formik.errors.shortDesc}
                          </div>
                        ) : null}
                      </Form.Group>
                      <Form.Group
                        className="mb-3"
                        controlId="exampleForm.ControlInput1"
                      >
                        <Form.Label>Long Description</Form.Label>
                        <JoditEditor
                          name="longDesc"
                          id="longDesc"
                          rows="5"
                          tabIndex={1} // tabIndex of textarea
                          onBlur={formik.handleBlur}
                          onChange={(newContent) =>
                            formik.setFieldValue("longDesc", newContent)
                          }
                          value={formik.values.longDesc}
                        />
                        {/* <Form.Control
                          as="textarea"
                          name="longDesc"
                          rows="8"
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          value={formik.values.longDesc}
                          placeholder=""
                          aria-label="With textarea"
                        /> */}
                        {formik.touched.longDesc && formik.errors.longDesc ? (
                          <div style={{ color: "red" }}>
                            {formik.errors.longDesc}
                          </div>
                        ) : null}
                      </Form.Group>
                      <div className="col-12">
                        <div className="mb-3">
                          <Label className="form-label">
                            {props.t("Image")}
                          </Label>
                          <div className="text-center">
                            <div className="mb-3 dragdrop-container">
                              <input
                                ref={uploadRef}
                                id="upload"
                                hidden
                                // accept="image/*"
                                accept=".png, .jpg, .jpeg"
                                type="file"
                                onChange={(e) =>
                                  handleImageChange(e.target.files)
                                }
                              />
                              {image.src ? (
                                <img
                                  className="all-img"
                                  src={image.src}
                                  alt=""
                                  onClick={() => {
                                    uploadRef.current.click();
                                  }}
                                />
                              ) : (
                                <div className="drag-n-drop-container">
                                  <div>
                                    <Dropzone
                                      //   accept="image/*"
                                      accept={{
                                        "image/jpeg": [".jpeg", ".png", ".jpg"],
                                      }}
                                      multiple={false}
                                      onDrop={(acceptedFiles) => {
                                        handleImageChange(acceptedFiles);
                                      }}
                                    >
                                      {({
                                        getRootProps,
                                        getInputProps,
                                        isDragActive,
                                      }) => (
                                        <section>
                                          <div
                                            className="drop-area"
                                            {...getRootProps()}
                                          >
                                            <img
                                              width={60}
                                              src={cloud}
                                              alt=""
                                            />
                                            <input
                                              {...getInputProps()}
                                              accept="image/*"
                                              multiple={false}
                                            />

                                            {isDragActive ? (
                                              <div>
                                                Drop your image file here
                                              </div>
                                            ) : (
                                              <p>
                                                {props.t("Drag Click")} <br />
                                                <small className="text-center"></small>
                                              </p>
                                            )}
                                          </div>
                                        </section>
                                      )}
                                    </Dropzone>
                                  </div>
                                </div>
                              )}
                            </div>
                          </div>
                          <p className="fw-bold">
                            {props.t("Note")}:
                            <span className="text-danger mx-2">
                              {props.t(
                                "Supported image formats are: jpg, png and jpeg only"
                              )}
                            </span>
                          </p>
                        </div>
                      </div>
                      <Button
                        type="submit"
                        variant="primary"
                        style={{ marginLeft: 10 }}
                      >
                        ADD
                      </Button>
                    </Form>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      </div>
    </React.Fragment>
  );
};

addProject.propTypes = {
  t: PropTypes.any,
  chartsData: PropTypes.any,
  onGetChartsData: PropTypes.func,
};

export default withTranslation()(addProject);
