import React from "react";
import { Redirect } from "react-router-dom";

// // Authentication related pages
import Login from "../pages/Authentication/Login";
import Logout from "../pages/Authentication/Logout";
import Register from "../pages/Authentication/Register";
import ForgetPwd from "../pages/Authentication/ForgetPassword";

// Admin Dashboard
import AdminCSMpages from "../admin/csm/index";
import AdminDashboard from "../admin/dashboard/index";
import AdminChangePassword from "../admin/account/index";
import AdminDeals from "../admin/deals/index";
import AdminDealsView from "../admin/deals/view";
import AdminDealsCreate from "../admin/deals/create";
import AdminLiquidityPools from "../admin/liquidity/index";
import AdminUsers from "../admin/users/index";
import AdminUserDetail from "../admin/users/detail";
import kycUsers from "admin/users/kycUsers";
import UserAirdrops from "../admin/airdrop";
import Faq from "../admin/faq/Faq";
import Plans from "../admin/plans/plans";
import category from "admin/category/category";
import staking from "admin/staking/index";
import productsList from "admin/products/productsList";
import support from "admin/support";
import addProject from "admin/newProjects/addProject";
import allProjects from "admin/newProjects/allProjects";
import proposal from "admin/proposal";

const authProtectedRoutes = [
  // Admin Routes
  { path: "/admin/dashboard", component: AdminDashboard },
  // { path: "/admin/change-password", component: AdminChangePassword },
  { path: "/admin/csm-pages", component: AdminCSMpages },
  { path: "/admin/profile", component: AdminChangePassword },
  { path: "/admin/support", component: support },
  { path: "/admin/deals", component: AdminDeals },
  { path: "/admin/deals/view", component: AdminDealsView },
  { path: "/admin/deals/create", component: AdminDealsCreate },
  { path: "/admin/liquidity-pools", component: AdminLiquidityPools },
  { path: "/admin/users", component: AdminUsers },
  { path: "/admin/kyc-users", component: kycUsers },
  { path: "/admin/airdrops", component: UserAirdrops },
  { path: "/admin/users/:userID", component: AdminUserDetail },
  { path: "/admin/faqs", component: Faq },
  { path: "/admin/plans", component: Plans },
  { path: "/admin/category", component: category },
  { path: "/admin/staking", component: staking },
  { path: "/admin/products", component: productsList },
  { path: "/admin/project-list", component: allProjects },
  { path: "/admin/add-project", component: addProject },
  { path: "/admin/proposal", component: proposal },

  { path: "/dashboard", component: AdminDashboard },
  { path: "/", exact: true, component: () => <Redirect to="/login" /> },
];

const publicRoutes = [
  { path: "/logout", component: Logout },
  { path: "/login", component: Login },
  // { path: "/forgot-password", component: ForgetPwd },
  // { path: "/register", component: Register },
  // { path: "*", component: staking },
];

export { authProtectedRoutes, publicRoutes };
