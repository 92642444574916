import React, { useEffect, useState } from "react"
import PropTypes from "prop-types"
import { Card, CardBody, CardTitle, Col, Row } from "reactstrap"
import ReactApexChart from "react-apexcharts"
import getChartColorsArray from "../../components/Common/ChartsDynamicColor"
import { Link } from "react-router-dom"
import moment from "moment"
import { Table, Thead, Tbody, Tr, Th, Td } from "react-super-responsive-table"
import axios from "axios"
// import { toast } from "react-toastify"
import { dateformat, dateTimeformat, toastCatchError } from "admin/commonFunction"
import Pagination, {
  bootstrap5PaginationPreset,
} from "react-responsive-pagination"
import { apiRoute } from "routes/apiRoutes"
import { transactionGraph } from "services/userServices"
import { Pie } from "react-chartjs-2"
import HommiesChart from "./HommiesChart"

const CardUser = ({ resultGraphStats, dataColors }) => {

  const apexCardUserChartColors = getChartColorsArray('["#089981"]')
  const [series, setSeries] = useState([
    {
      name: "Transactions",
      data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
    },
  ])

  var pieChartColors = getChartColorsArray(dataColors);
  const [pieData, setPieData] = useState({
    labels: ["Desktops", "Tablets", "TEST"],
    datasets: [
      {
        data: [300, 180, 635],
        backgroundColor: pieChartColors,
        hoverBackgroundColor: pieChartColors,
        hoverBorderColor: "#fff",
      },
    ],
  })

  const options = {
    chart: {
      height: 350,
      type: "area",
      toolbar: {
        show: false,
      },
    },
    colors: apexCardUserChartColors,
    dataLabels: {
      enabled: false,
    },
    stroke: {
      curve: "smooth",
      width: 2,
    },
    fill: {
      type: "gradient",
      gradient: {
        shadeIntensity: 1,
        inverseColors: false,
        opacityFrom: 0.45,
        opacityTo: 0.05,
        stops: [20, 100, 100, 100],
      },
    },
    xaxis: {
      categories:
        [
          "Jan",
          "Feb",
          "Mar",
          "Apr",
          "May",
          "Jun",
          "Jul",
          "Aug",
          "Sep",
          "Oct",
          "Nov",
          "Dec",
        ],
    },

    markers: {
      size: 3,
      strokeWidth: 3,

      hover: {
        size: 4,
        sizeOffset: 2,
      },
    },
    legend: {
      position: "top",
      horizontalAlign: "right",
    },
  }

  const [transactionStats, setTransactionStats] = useState()
  const graphInfo = async () => {
    try {

      let response = await transactionGraph()
      if (response.status == 200) {

        console.log(response?.data, "<<--- response?.data")
        let stats = response?.data
        setTransactionStats(stats)
        let graphArr = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
        let arr = stats?.transactionGraph;
        for (let index = 0; index < arr.length; index++) {
          const element = arr[index];
          graphArr[element?.month-1] = element?.total

        }
        setSeries([
          {
            name: "$",
            data: graphArr,
          },
        ])


        let planName = [];
        let planActive = [];
        let listing = stats?.planListing;
        for (let index = 0; index < listing.length; index++) {
          const element = listing[index];
          planName.push(element?.title)
          planActive.push(element?.activeUserCount)

        }
        setPieData({
          labels: planName,
          datasets: [
            {
              data: planActive,
              backgroundColor: pieChartColors,
              hoverBackgroundColor: pieChartColors,
              hoverBorderColor: "#fff",
            },
          ],
        })

      }

    } catch (error) {
      // toastCatchError(error)
      graphInfo()
    }
  }

  useEffect(() => {
    graphInfo()
  }, [])




  return (
    <React.Fragment>
      <Row>

        <Col lg={8} sm="6" className="pe-0">
          <Card>
            <CardBody>
              {/* <CardTitle className="mb-4"> Active Storage Plans </CardTitle> */}
              {/* <Pie height={185} data={pieData} /> */}

              <HommiesChart />
            </CardBody>
          </Card>
        </Col>

        <Col lg="4" sm="6">
          <Card className="mb-2" style={{padding: "20px"}}>
            <CardBody className="" style={{border: "1px solid #e0e3eb", borderRadius: "2px"}}>
              <h4 className="card-title mb-4">Platform Transactions</h4>
              <div className="mt-4">
                <div id="wallet-balance-chart" className="mt-2">
                  <ReactApexChart
                    options={options}
                    series={series}
                    type="area"
                    height={315}
                    className="apex-charts"
                  />
                </div>
              </div>
            </CardBody>
          </Card>
        </Col>

      </Row>
    </React.Fragment >
  )
}

export default CardUser
