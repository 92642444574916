import appendData from "common/utility";
import {
  del,
  get,
  getWithExportFile,
  post,
  postAsForm,
  put,
} from "helpers/api_helper";

export const getAllCategory = () => {
  return get("/product/getAllCategory");
};

export const createCategory = (data) => {
  return post("/product/createCategory", data);
};
export const createNewProject = (data) => {
  const formData = appendData(data);
  return postAsForm("/admin/project/newProjectListings", formData);
};

export const deleteCategoryData = (data) => {
  return del("/product/deleteCategory", data);
};
export const deleteSubCategoryData = (data) => {
  return del("/product/deleteSubCategory", data);
};

export const createSubCategory = (data) => {
  return post("/product/createsubCategory", data);
};

export const getAllMarketplaceProducts = (
  pageNumber,
  pageSize,
  filter = "",
  sortBy = "",
  categoryId = "",
  subcategoryId = ""
) => {
  return getWithExportFile(
    `/admin/marketplace/products?pageNumber=${pageNumber}&pageSize=${pageSize}&search=${filter}&sortBy=${sortBy}&categoryId=${categoryId}&subcategoryId=${subcategoryId}`
  );
};

export const getAllProjectListing = (pageNumber, pageSize, filter = "") => {
  return getWithExportFile(
    `/admin/project/newProjectPlan?pageNumber=${pageNumber}&pageSize=${pageSize}&search=${filter}`
  );
};

export const updateProductStatus = (data) => {
  return post("/admin/marketplace/status/change", data);
};

export const deleteProjectData = (data) => {
  return del("/admin/project/deleteProject", data);
};
