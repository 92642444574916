import React, { useState } from "react";
import ClipLoader from "react-spinners/ClipLoader";
const Loader = () => {
  return (
    <div>
      <div
        className="d-flex justify-content-center"
        style={{
          textAlign: "center",
          alignItems: "center",
          width: "100%",
          height: "100vh",
          backgroundColor: "white",
        }}
      >
        <ClipLoader
          // size={20}
          // color="#0000ffde"
          color="#000000"
        />
      </div>
    </div>
  );
};

export default Loader;
