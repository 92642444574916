import React from "react";
import { withRouter } from "react-router-dom";
import PropTypes from "prop-types";

//i18n
import { withTranslation } from "react-i18next";

const NoRecordTable = (props) => {
  return (
    <div className="my-5 text-center font-size-15">
      {props.t("No Records Available")}
    </div>
  );
};

NoRecordTable.propTypes = {
  t: PropTypes.any,
};
export default withRouter(withTranslation()(NoRecordTable));
