import PropTypes from "prop-types"
import React, { useEffect, useState } from "react"
import ReactApexChart from "react-apexcharts"
import { Link } from "react-router-dom"
import { Card, CardBody, Col, Row } from "reactstrap"
import getChartColorsArray from "../../components/Common/ChartsDynamicColor"
// import { toast } from "react-toastify"
import { toastCatchError } from "admin/commonFunction"
import { transactionGraph } from "services/userServices"
import { Pie } from "react-chartjs-2"

const PlanChart = ({ resultGraphStats, dataColors }) => {

  var pieChartColors = getChartColorsArray(dataColors);
  const data = {
    labels: ["Desktops", "Tablets"],
    datasets: [
      {
        data: [300, 180],
        backgroundColor: pieChartColors,
        hoverBackgroundColor: pieChartColors,
        hoverBorderColor: "#fff",
      },
    ],
  }

  const [transactionStats, setTransactionStats] = useState()


  return (
    <React.Fragment>
      <Card className="mb-2">
        <CardBody className="border">
          <Row>
            <Col lg="9" sm="6" className="pt-3">
              <h4 className="card-title mb-4">Transactions</h4>
              <div className="mt-4">
                <div id="wallet-balance-chart" className="mt-2">

                  <Pie width={751} height={260} data={data} />
                </div>
              </div>
            </Col>
            <Col lg="3">
              <div className="mt-0">
                <Row className="mt-4">
                  <Col xs="12" className="mb-0">
                    <Card
                      className="pb-0 mb-2"
                      style={{ backgroundColor: "rgb(41 42 50)" }}
                    >
                      <div className="p-4">
                        <Link to="/admin/dashboard">
                          <div className="d-flex flex-wrap">
                            <div className="me-3">
                              <p
                                className="mb-2 text-white"
                                style={{ fontSize: "15px" }}
                              >
                                Today's Transactions
                              </p>
                              <h5 className="mb-0" style={{ color: "white" }}>
                                {transactionStats ? transactionStats?.todayTransactions[0]?.totalAmount ? transactionStats?.todayTransactions[0]?.totalAmount.toFixed(2) : "0.00" : "0.00"}
                              </h5>
                            </div>
                            <div className="avatar-sm ms-auto">
                              <div className="avatar-title bg-light rounded-circle text-primary font-size-20">
                                <i
                                  className="bx bxs-user-circle"
                                  style={{ color: "#4c4747" }}
                                ></i>
                              </div>
                            </div>
                          </div>
                        </Link>
                      </div>
                    </Card>

                    <Card
                      className="pb-0 mb-2"
                      style={{ backgroundColor: "rgb(41 42 50)" }}
                    >
                      <div className="p-4">
                        <Link to="/admin/dashboard">
                          <div className="d-flex flex-wrap">
                            <div className="me-3">
                              <p
                                className="mb-2 text-white"
                                style={{ fontSize: "15px" }}
                              >
                                Last 7 Days
                              </p>
                              <h5 className="mb-0" style={{ color: "white" }}>
                                {transactionStats ? transactionStats?.weekTransactions[0]?.totalAmount ? transactionStats?.weekTransactions[0]?.totalAmount.toFixed(2) : "0.00" : "0.00"}
                              </h5>
                            </div>
                            <div className="avatar-sm ms-auto">
                              <div className="avatar-title bg-light rounded-circle text-primary font-size-20">
                                <i
                                  className="bx bxs-user-circle"
                                  style={{ color: "#4c4747" }}
                                ></i>
                              </div>
                            </div>
                          </div>
                        </Link>
                      </div>
                    </Card>

                    <Card
                      className="pb-0 mb-2"
                      style={{ backgroundColor: "rgb(41 42 50)" }}
                    >
                      <div className="p-4">
                        <Link to="/admin/dashboard">
                          <div className="d-flex flex-wrap">
                            <div className="me-3">
                              <p
                                className="mb-2 text-white"
                                style={{ fontSize: "15px" }}
                              >
                                Last 30 Days
                              </p>
                              <h5 className="mb-0" style={{ color: "white" }}>
                                {transactionStats ? transactionStats?.monthTransactions[0]?.totalAmount ? transactionStats?.monthTransactions[0]?.totalAmount.toFixed(2) : "0.00" : "0.00"}
                              </h5>
                            </div>
                            <div className="avatar-sm ms-auto">
                              <div className="avatar-title bg-light rounded-circle text-primary font-size-20">
                                <i
                                  className="bx bxs-user-circle"
                                  style={{ color: "#4c4747" }}
                                ></i>
                              </div>
                            </div>
                          </div>
                        </Link>
                      </div>
                    </Card>
                  </Col>
                </Row>
              </div>
            </Col>
          </Row>
        </CardBody>

      </Card>
    </React.Fragment>
  )
}

PlanChart.propTypes = {
  // options: PropTypes.any,
  // series: PropTypes.any,
}

export default PlanChart
