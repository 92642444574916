import React, { useState, useContext, useEffect } from "react";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import { LoaderContext } from "context/ContextProvider.js";
import Modal from "react-bootstrap/Modal";
import { fireToast } from "common/toast";
import { useFormik } from "formik";
import * as Yup from "yup";
import { createSubCategory, getAllCategory } from "services/helper";
const AddSubCategory = ({
    openSub,
    setSubOpen,
    fetchData,
    subedit,
    Data
}) => {
    const initialValues = {
        name: subedit.name ? subedit.name : "",
        categoryId: subedit.categoryId ? subedit.categoryId : ""
    };
    const formik = useFormik({
        initialValues: initialValues,
        enableReinitialize: true,
        validationSchema: Yup.object({
            name: Yup.string().required("Required"),
            categoryId: Yup.string().required("Required")
        }),
        onSubmit: async (values, { resetForm }) => {
            try {
                if (!categoryId || categoryId == "") {
                    return;
                }
                const response = await createSubCategory({ ...values, id: subedit?._id });
                if (response.status == 200) {
                    setSubOpen(false)
                    fireToast("success", response.message);
                    resetForm()
                    fetchData()
                }
            } catch (error) {
                fireToast("error", error.response.data.error);
            }
        },
    });

    console.log(Data, "sddsadd");

    return (
        <>
            <Modal show={openSub} onHide={() => setSubOpen(false)}>
                <Modal.Header closeButton>
                    <Modal.Title>{subedit ? "Update SubCategory" : "Add SubCategory"}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <form className="" onSubmit={formik.handleSubmit}>
                        <div className="row py-3">

                            <div className="col-md-12">
                                <label htmlFor="categoryId">Category</label>
                                <select className={`form-select`}
                                    placeholder="name"
                                    onChange={formik.handleChange}
                                    value={formik?.values?.categoryId}
                                    onBlur={formik.handleBlur}
                                    id="categoryId">
                                    <option value="">Open this select menu</option>
                                    {Data?.map((element, index) => (
                                        <option key={element?._id} value={element?._id}>{element?.name}</option>
                                    ))}
                                </select>
                                {formik.touched.categoryId && formik.errors.categoryId ? (
                                    <div className="text-danger">{formik.errors.categoryId}</div>
                                ) : null}
                            </div>
                            <div className="col-md-10 mt-3">
                                <label htmlFor="name">Sub-Category</label>
                                <input
                                    className={`form-control`}
                                    type="text"
                                    placeholder="Write here"
                                    onChange={formik.handleChange}
                                    value={formik?.values?.name}
                                    onBlur={formik.handleBlur}
                                    id="name"
                                />
                                {formik.touched.name && formik.errors.name ? (
                                    <div className="text-danger">{formik.errors.name}</div>
                                ) : null}
                            </div>

                            <div className="col-md-2 mt-3 p-0">

                                <button
                                    type="submit"
                                    className="btn btn-secondary"
                                    style={{ marginTop: "26px" }}
                                >
                                    {subedit ? "Update" : "Add"}
                                </button>
                            </div>
                        </div>
                    </form>

                </Modal.Body>
            </Modal>
        </>
    );
}

export default AddSubCategory;
