import React, { useState, useEffect } from "react"
import { Link } from "react-router-dom"
import {
  Row,
  Col,
  Card,
  CardBody,
} from "reactstrap"

//Import Images
import avatar1 from "../../assets/images/dashboard-logo.png"
import axios from "axios"
//import components
import { JobWidgetCharts } from '../JobCharts';

import '../../assets/scss/style.css'
import { dashboardStats } from "services/userServices"
import { toastCatchError } from "admin/commonFunction"

function CardUser(prop) {
  const [settingsMenu, setSettingsMenu] = useState(false)
  const graph1 = [{
    name: "Job View",
    data: [36, 21, 65, 22, 35, 50, 87, 98],
  }];

  const [stats, setStats] = useState()
  const getDashboardInfo = async () => {
    try {

      let response = await dashboardStats();
      console.log(response, "<<---- dashboardStats");
      if (response.status == 200) {
        setStats(response.data)
      }

    } catch (error) {
      // toastCatchError(error)
      getDashboardInfo()
    }
  }

  useEffect(() => {
    getDashboardInfo();
  }, [])

  return (
    <React.Fragment>

      <Row>
        <Col lg="12">
          <Card className="mb-2">
            <CardBody className="border">
              <Row className="background-user-image">
                <Col lg="3" className="p-0 pt-3 ps-3 m-0">
                  <div className="d-flex">
                    <div className="me-3">

                      <img
                        src={avatar1}
                        alt=""
                        className=""
                        style={{
                          width: "11.0rem",
                          marginLeft: "25px"
                        }}
                      />
                    </div>

                  </div>
                </Col>

                <Col lg="9" className="align-self-center">
                  <div className="text-lg-center mt-4 mt-lg-0">
                    <Row>
                      <Col xs="3">
                        <div className="py-4">
                          <div className="d-flex flex-wrap">
                            <div className="me-3">
                              <p className="text-muted mb-2 font-size-16">Total Users</p>
                              <h5 className="mb-0"> {stats?.userCount ? stats?.userCount : 0} </h5>
                            </div>
                          </div>
                        </div>
                      </Col>
                      <Col xs="3">
                        <div className="py-4">
                          <div className="d-flex flex-wrap">
                            <div className="me-3">
                              <p className="text-muted mb-2 font-size-16">Premium Users</p>
                              <h5 className="mb-0"> {stats?.premiumCount ? stats?.premiumCount : 0} </h5>
                            </div>
                          </div>
                        </div>
                      </Col>

                      <Col xs="3">
                        <div className="py-4">
                          <div className="d-flex flex-wrap">
                            <div className="me-3">
                              <p className="text-muted mb-2 font-size-16">
                                Total Tranactions
                              </p>
                              <h5 className="mb-0">{stats ? stats?.totalTransactions[0]?.totalAmount ? stats?.totalTransactions[0]?.totalAmount.toFixed(2) : 0 : 0} </h5>
                            </div>
                          </div>
                        </div>
                      </Col>
                      <Col xs="3">
                        <div className="py-4">
                          <div className="d-flex flex-wrap">
                            <div className="me-3">
                              <p className="text-muted mb-2 font-size-16">
                                Today's Tranactions
                              </p>
                              <h5 className="mb-0">{stats ? stats?.todayTransactions[0]?.totalAmount ? stats?.todayTransactions[0]?.totalAmount.toFixed(2) : 0.00 : 0} </h5>
                            </div>
                          </div>
                        </div>
                      </Col>
                    </Row>
                  </div>
                </Col>
              </Row>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </React.Fragment>
  )
}

export default CardUser
