import React, { useContext, useEffect, useState } from "react"
import Button from "react-bootstrap/Button"
import { Modal, Form } from "react-bootstrap"
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { withRouter, Link } from "react-router-dom"
import {
    Card,
    CardBody,
    Col,
    Row,
    Container,
    Badge,
    CardTitle,
    FormGroup,
    Input,
    InputGroup,
} from "reactstrap"
import { Table, Thead, Tbody, Tr, Th, Td } from "react-super-responsive-table"
import { LoaderContext } from "context/ContextProvider"
import axios from "axios"
import { element } from "prop-types"
import { apiHeader, apiRoute } from "helpers/api_helper";
import { updateNewPlan, addNewPlans, deletePlanData, getPlanDetail } from "services/userServices";
import { alerShow, toastCatchError } from "admin/commonFunction";
import { ethers } from "ethers";
import { dateStringToDate, walletAddressShort } from "common/utility";
import { config } from "config/blockChainConfig";
import { BlockChainContext } from "context/blockChainContext";
import Loader from "common/loader";
function Plan() {
    const {
        Add,
        Edit,
        showEdit,
        handleShow,
        handelShow1,
        handleClose,
        setloading,
    } = useContext(LoaderContext)

    let { provider, singer, walletAddress, stakingInstance, connectWallet, createStakingInstance, setProvider, setSigner, setWalletAddress, setStakingInstance } = useContext(BlockChainContext)

    const [loading, setLoading] = useState(false);

    const formik = useFormik({
        initialValues: {
            title: '',
            duration: '',
            apy_interest: '',
            price: '',
            max_price: ''
        },
        validationSchema: Yup.object({
            title: Yup.string().required('This field is required.'),
            duration: Yup.string().required('This field is required.'),
            price: Yup.number()
                .required('This field is required.')
                .test('price-less-than-max-price', 'Price must be less than Max Price.', function (value) {
                    const maxPrice = this.resolve(Yup.ref('max_price'));
                    return value < maxPrice;
                }),
            max_price: Yup.number()
                .required('This field is required.')
                .test('max-price-greater-than-price', 'Max Price must be greater than Price.', function (value) {
                    const price = this.resolve(Yup.ref('price'));
                    return value > price;
                }),
            apy_interest: Yup.number()
                .max(100, 'APY must be less than 100.')
                .required('This field is required.')
        }),
        onSubmit: values => {
            createStakingPlan(values)
        },
    });
    const createStakingPlan = async (data) => {
        try {

            console.log(data, "<<--- form data")
            setLoading(true)
            if (!walletAddress || !provider) {
                await connectWallet()
            }
            if (!stakingInstance) {
                stakingInstance = await createStakingInstance()
            }

            let minAmount = ethers.utils.parseUnits(data.price.toString(), 18)
            let maxAmount = ethers.utils.parseUnits(data.max_price.toString(), 18)
            let apyInterest = ethers.utils.parseUnits(data.apy_interest.toString(), 18)

            let oneDaySeconds = Number(config.secondsInDay); // Seconds in one day
            let finalDuration = Number(data.duration) * Number(oneDaySeconds);

            console.log(data.title.toLowerCase(), apyInterest, minAmount, maxAmount, data.duration, finalDuration, "<<<--- details")

            const createPlanTrans = await stakingInstance.createPlan(data.title.toLowerCase(), apyInterest, minAmount, maxAmount, finalDuration, {
                gasLimit: 300000,
            })
            if (createPlanTrans && createPlanTrans.hash) {
                setLoading(false)
                handleClose()
                console.log(createPlanTrans, "createPlanTrans")

                Swal.fire({
                    title: "Plan Created!",
                    icon: "success",
                    html: `
              Click to check your transaction details <a href="${config.scanURL}/${createPlanTrans.hash}" target="_blank"><i class="mdi mdi-open-in-new" aria-hidden="true"></i> </a>  <h6> Refresh List After Completion! </h6>
            `,
                    showCloseButton: true,
                    showCancelButton: false,
                    focusConfirm: false,
                    confirmButtonText: `Success!`,
                    allowOutsideClick: false
                });
            }


        } catch (error) {
            setLoading(false)
            console.log("creating staking plan error ---->>>", error)
            alerShow("Error!", error?.data?.message ? error?.data?.message : error?.reason ? error?.reason : "Please verify that the connected wallet belongs to the owner", "error");
        }
    }

    const [stakingListing, setStakingListing] = useState([])
    const getStakingPlans = async () => {
        try {
            if (!walletAddress || !provider) {
                await connectWallet()
            }
            if (!stakingInstance) {
                stakingInstance = await createStakingInstance()
            }

            setLoading(true)
            const listingOfStakingPlans = await stakingInstance.getAllPlans({
                gasLimit: 300000,
            })
            console.log(listingOfStakingPlans, "listingOfStakingPlans")
            setStakingListing(listingOfStakingPlans)

        } catch (error) {
            console.log("getting staking plan error ------", error.message)
            alerShow("Error!", error?.data?.message ? error?.data?.message : error?.reason ? error?.reason : "Please verify that the connected wallet belongs to the owner", "error");
        } finally {
            setLoading(false)
        }
    }

    const handleStakingStatus = async (name) => {
        try {

            if (!walletAddress || !provider) {
                await connectWallet()
            }
            if (!stakingInstance) {
                stakingInstance = await createStakingInstance()
            }

            let result = await stakingInstance.togglePlanState(name);
            if (result) {
                // alerShow("Success", "Refresh List After Completion!")
            }

        } catch (error) {
            getStakingPlans()
            console.log("staking status error ------", error.message)
            alerShow("Error!", error?.data?.message ? error?.data?.message : error?.reason ? error?.reason : "Please verify that the connected wallet belongs to the owner", "error");
        }
    }


    const deletePlan = async (name) => {
        try {
            setLoading(true)
            if (!walletAddress || !provider) {
                await connectWallet()
            }
            if (!stakingInstance) {
                stakingInstance = await createStakingInstance()
            }

            let result = await stakingInstance.deletePlan(name);
            setLoading(false)
            if (result) {
                alerShow("Success", "Refresh List After Completion!")
            }

        } catch (err) {
            setLoading(false)
            console.log("staking plan deleting error ------", err.message)
            // alerShow("Error!", "An error occurred !", "error");
            alerShow("Error!", err?.data?.message ? err?.data?.message : err?.reason ? err?.reason : "Please verify that the connected wallet belongs to the owner", "error");
        }
    }

    const ConfirmDelete = (name) => {
        Swal.fire({
            title: "Are you sure?",
            text: "You won't be able to revert this!",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "Yes, delete it!",
        }).then(result => {
            if (result.isConfirmed) {
                deletePlan(name)
            }
        })
    }

    // const [stakingInfo, setStakingInfo] = useState(null)
    // const getStakingInformation = async (data) => {
    //     try {

    //         setLoading(true)
    //         if (!walletAddress || !provider) {
    //             await connectWallet()
    //         }
    //         if (!stakingInstance) {
    //             stakingInstance = await createStakingInstance()
    //         }

    //         console.log(stakingInstance, "<<<-- stakingInstance")
    //         let totalStakingAmount = await stakingInstance.getTotalStakedAmount();
    //         let getTotalHommiesInContract = await stakingInstance.getTotalHommiesInContract();

    //         setStakingInfo({
    //             totalInterestAmount: ethers.utils.formatEther(totalStakingAmount?.totalInterestAmount?.toString(), 18),
    //             totalStakedAmount: ethers.utils.formatEther(totalStakingAmount?.totalStakedAmount?.toString(), 18),
    //             totalStakedAmountAfterTax: ethers.utils.formatEther(totalStakingAmount?.totalStakedAmountAfterTax?.toString(), 18),
    //             totalHommiesInContract: ethers.utils.formatEther(getTotalHommiesInContract?.toString(), 18),
    //         })

    //     } catch (error) {

    //     }
    // }

    // useEffect(() => {
    //     getStakingInformation()
    // }, [stakingInstance])

    useEffect(() => {
        connectWallet()
    }, [])

    useEffect(() => {
        formik.resetForm();
    }, [Add])

    useEffect(() => {
        if (provider && walletAddress) {
            getStakingPlans()
        }
    }, [walletAddress])

    const handleInputChange = (event) => {
        const { name, value } = event.target;

        // Regex pattern to allow only numbers, including decimal numbers
        const regex = /^\d*\.?\d*$/;
        if (regex.test(value) || value === '') {
            formik.handleChange(event);
        }
    };


    return (
        <>
            <div className="page-content">
                <div className="container-fluid">

                    <Row>
                        <Col lg="12">
                            <Card>
                                <CardBody className="border-bottom py-2">
                                    <div className="d-flex align-items-center">
                                        <h5 className="mb-0 card-title flex-grow-1 text-color-gredient">
                                            {" "}
                                            Staking Plans
                                        </h5>
                                        <div style={{ marginRight: '0px !important' }}>
                                            <button
                                                type="button"
                                                className="btn btn-secondary mt-3 me-2"
                                                onClick={handelShow1}
                                            >
                                                <i className="bx bx-plus"></i> Add plans
                                            </button>
                                            {
                                                walletAddress ? <> <button
                                                    type="button"
                                                    className="btn btn-secondary mt-3 me-2"
                                                >
                                                    <i className="mdi mdi-wallet me-1"></i> {walletAddress ? walletAddressShort(walletAddress) : walletAddress}
                                                </button>

                                                </>
                                                    : <button
                                                        type="button"
                                                        className="btn btn-secondary mt-3 me-2"
                                                        onClick={connectWallet}
                                                    >
                                                        <i className="mdi mdi-wallet me-1"></i> Connect Wallet
                                                    </button>
                                            }

                                            <button
                                                type="button"
                                                className="btn btn-secondary mt-3 me-2"
                                                onClick={getStakingPlans}
                                            >
                                                <i className="mdi mdi-refresh"></i>
                                            </button>
                                        </div>
                                    </div>
                                </CardBody>

                                <CardBody style={{ padding: 16 }}>
                                    <div className="table-responsive">
                                        <Table
                                            id="tech-companies-1"
                                            className="table table-striped table-bordered"
                                        >
                                            <Thead>
                                                <Tr>
                                                    <Th>Sr.No</Th>
                                                    <Th> Title </Th>
                                                    <Th> APY (%) </Th>
                                                    <Th> No. of Days </Th>
                                                    <Th> Minimum Staking Amount </Th>
                                                    <Th> Maximum Staking Amount </Th>
                                                    <Th> Created On </Th>
                                                    <Th> Status </Th>
                                                    <Th> Action </Th>
                                                </Tr>
                                            </Thead>
                                            <Tbody>
                                                {
                                                    loading ?
                                                        <tr>
                                                            <td colSpan={8}> Loading ... </td>
                                                        </tr>
                                                        :
                                                        stakingListing.length > 0 ? stakingListing?.map((element, index) => {
                                                            return (
                                                                <tr key={index}>

                                                                    <td style={{ paddingLeft: 15 }}>
                                                                        <span className="co-name"> {index + 1}.</span>
                                                                    </td>

                                                                    <td> <h5 className="font-size-14 text-color-gredient">{element.name}</h5></td> <td>{element?.interestRate ? parseFloat(Number(element.interestRate.toString()) / 1e18) + " %" : "-"} </td>

                                                                    <td>{element?.duration ? Number(element.duration.toString()) / Number(config.secondsInDay) : "-"} Days </td>

                                                                    <td>{element?.minAmount ? parseFloat(Number(element.minAmount.toString()) / 1e18) + " Hommies " : "-"} </td>

                                                                    <td>{element?.maxAmount ? parseFloat(Number(element.maxAmount.toString()) / 1e18) + " Hommies " : "-"} </td>

                                                                    <td>{element?.createdDate ? dateStringToDate(element.createdDate.toString()) : "-"} </td>
                                                                    <td>

                                                                        <div className="square-switch mytoggle">
                                                                            <input
                                                                                type="checkbox"
                                                                                id={`square-switch${element.name}`}
                                                                                switch="success"
                                                                                defaultChecked={Boolean(!element.inActive)}
                                                                                value={element.name}
                                                                                onClick={() => handleStakingStatus(element.name)}
                                                                            />
                                                                            <label
                                                                                htmlFor={`square-switch${element.name}`}
                                                                                data-on-label="Active"
                                                                                data-off-label="Inactive"
                                                                            />
                                                                        </div>
                                                                    </td>
                                                                    <td>
                                                                        <ul className="list-unstyled hstack gap-1 mb-0">
                                                                            <li>
                                                                                <button
                                                                                    className="btn btn-sm btn-danger"
                                                                                    onClick={() => {
                                                                                        ConfirmDelete(element.name)
                                                                                    }}>
                                                                                    <i className="mdi mdi-delete-outline" />
                                                                                </button>
                                                                            </li>
                                                                        </ul>
                                                                    </td>
                                                                </tr>
                                                            )
                                                        })
                                                            :
                                                            <tr>
                                                                <td colSpan={8}> No Record Found! </td>
                                                            </tr>
                                                }
                                            </Tbody>
                                        </Table>
                                    </div>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </div>
            </div>
            <Modal
                size='lg'
                show={Add}
                onHide={handleClose}
                aria-labelledby="contained-modal-title-vcenter"
                backdrop="static"
                centered
            >
                <Modal.Header closeButton>
                    <Modal.Title>Add Staking Plan</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form onSubmit={formik.handleSubmit}>
                        <div className="row mb-4">
                            <div className="col-6">
                                <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                    <Form.Label>Plan Name</Form.Label>
                                    <Form.Control
                                        type="text"
                                        name="title"
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                        value={formik.values.title}
                                        placeholder="Write here..."
                                        autoFocus='true'
                                    />
                                    {formik.touched.title && formik.errors.title ? (
                                        <div style={{ color: 'red' }}>{formik.errors.title}</div>
                                    ) : null}
                                </Form.Group>
                            </div>
                            <div className="col-6">
                                <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                    <Form.Label>Duration of the plan (in days)</Form.Label>
                                    <Form.Control
                                        type="number"
                                        min={0}
                                        name="duration"
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                        value={formik.values.duration}
                                        placeholder="Write here..."
                                        autoFocus='true'
                                    />
                                    {formik.touched.duration && formik.errors.duration ? (
                                        <div style={{ color: 'red' }}>{formik.errors.duration}</div>
                                    ) : null}
                                </Form.Group>
                            </div>
                            <div className="col-6">
                                <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                    <Form.Label>Minimum Hommies</Form.Label>
                                    <Form.Control
                                        type="number"
                                        min={0}
                                        name="price"
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                        value={formik.values.price}
                                        placeholder="Write here..."
                                        autoFocus='true'
                                    />
                                    {formik.touched.price && formik.errors.price ? (
                                        <div style={{ color: 'red' }}>{formik.errors.price}</div>
                                    ) : null}
                                </Form.Group>
                            </div>
                            <div className="col-6">
                                <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                    <Form.Label>Maximum Hommies</Form.Label>
                                    <Form.Control
                                        type="number"
                                        min={0}
                                        name="max_price"
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                        value={formik.values.max_price}
                                        placeholder="Write here..."
                                        autoFocus='true'
                                    />
                                    {formik.touched.max_price && formik.errors.max_price ? (
                                        <div style={{ color: 'red' }}>{formik.errors.max_price}</div>
                                    ) : null}
                                </Form.Group>
                            </div>
                            <div className="col-6">
                                <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                    <Form.Label>Interest Rate (in percentage for whole duration)</Form.Label>
                                    <Form.Control
                                        type="text"
                                        min={0}
                                        name="apy_interest"
                                        onChange={handleInputChange}
                                        // onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                        value={formik.values.apy_interest}
                                        placeholder="Write here..."
                                        autoFocus='true'
                                    />
                                    {formik.touched.apy_interest && formik.errors.apy_interest ? (
                                        <div style={{ color: 'red' }}>{formik.errors.apy_interest}</div>
                                    ) : null}
                                </Form.Group>
                            </div>
                        </div>
                        <Button variant="secondary" onClick={handleClose}>
                            Close
                        </Button>
                        {
                            loading ?
                                <Button type="button" variant="primary" style={{ marginLeft: 10 }}>
                                    Loading ...
                                </Button> :
                                <Button type="submit" variant="primary" style={{ marginLeft: 10 }}>
                                    ADD
                                </Button>
                        }
                    </Form>
                </Modal.Body>
                <Modal.Footer></Modal.Footer>
            </Modal>

        </>
    )
}
export default withRouter(Plan)
