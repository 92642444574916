import PropTypes, { element, func } from "prop-types";
import React, { useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import {
  Card,
  CardBody,
  Col,
  Row,
  Container,
  Badge,
  CardTitle,
  FormGroup,
  Input,
  InputGroup,
} from "reactstrap";
import { Table, Thead, Tbody, Tr, Th, Td } from "react-super-responsive-table";
import { firstLetterCapital } from "common/utility";
import "../../assets/scss/style.css";
import "flatpickr/dist/themes/material_blue.css";
import avatar from "../../assets/images/users/dummy.png";
import {
  getAllMarketplaceProducts,
  getAllCategory,
  updateProductStatus,
} from "services/helper";
import { withTranslation } from "react-i18next";
import { apiRoute } from "routes/apiRoutes";
import { LoaderContext } from "context/ContextProvider";
import axios from "axios";
import { toast } from "react-toastify";
import {
  dateformat,
  handleClickCopy,
  longStringConvertToShort,
} from "admin/commonFunction";
import Pagination, {
  bootstrap5PaginationPreset,
} from "react-responsive-pagination";

const ProductsList = (props) => {
  const {
    Add,
    Edit,
    showEdit,
    handleShow,
    handelShow1,
    handleClose,
    setloading,
  } = useContext(LoaderContext);

  document.title = "Products";
  const BASE_URL = process.env.REACT_APP_BASE_URL;
  const [listingData, setData] = useState([]);
  const [searchName, setSearchName] = useState("");
  const [statusFilter, setStatusFilter] = useState("");
  const [categoryFilter, setCategoryFilter] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [totalCount, setTotalCount] = useState(0);
  const [categories, setCategories] = useState([]);
  const [loaderStatus, setLoaderStatus] = useState(true);
  let [incermentInd, setIncrementInd] = useState(1);
  const perPage = 10;

  //fetch users
  const fetchData = async (
    pagenumber,
    pagelimit,
    search = "",
    status = "",
    categoryId = "",
    subcategoryId = ""
  ) => {
    try {
      setLoaderStatus(true);
      await getAllMarketplaceProducts(
        pagenumber,
        pagelimit,
        search,
        status,
        categoryId,
        ""
      ).then((res) => {
        console.log(res, "product listing >>>>>>");
        setData(res?.data?.items);

        let pageCount = Math.ceil(res.data.totalItems / perPage);
        setTotalCount(pageCount);
        setLoaderStatus(false);
      });
    } catch (err) {
      setLoaderStatus(false);
      console.log(err);
    }
  };
  const fetchCategories = async () => {
    try {
      await getAllCategory().then((res) => {
        console.log(res?.data, ">>>>>>>>>");
        setCategories(res?.data);
      });
    } catch (err) {
      console.log(err);
    }
  };
  useEffect(() => {
    fetchData(currentPage, perPage);
    fetchCategories();
  }, []);

  const handlePageClick = (page) => {
    setCurrentPage(page);
    fetchData(page, perPage, searchName, statusFilter, categoryFilter);
  };

  const [typingTimeout, setTypingTimeout] = useState(0);
  const handleSearchClick = (e) => {
    const value = e.target.value;
    setSearchName(value);
    setCurrentPage(1);
    clearTimeout(typingTimeout);
    const timeoutId = setTimeout(() => {
      fetchData(1, perPage, e.target.value, statusFilter);
    }, 500);

    setTypingTimeout(timeoutId);

    // setSearchName(event.target.value)
    // setCurrentPage(1)
    // fetchData(1, perPage, event.target.value, statusFilter)
  };

  const handleStatusFilter = (e) => {
    console.log(e.target.value);
    setStatusFilter(e.target.value);
    fetchData(1, perPage, searchName, e.target.value, categoryFilter);
  };

  const handleCategoryFilter = (e) => {
    console.log(e.target.value, "eeeeee");
    setCategoryFilter(e.target.value);
    fetchData(1, perPage, searchName, statusFilter, e.target.value);
  };

  const [modal, setmodal] = useState(false);

  const handleChangeCheck = async (event) => {
    console.log(event.target.value);
    console.log(event.target.checked);

    let userID = event.target.value;
    let isApproved = true;
    if (!event.target.checked) {
      isApproved = false;
    }

    let data = {
      productStatus: isApproved.toString(),
      productId: userID,
    };
    updateProductStatus(data)
      .then(function (response) {
        toast.success(response.data.message);
      })
      .catch(function (error) {
        toast.error(error.response.data.error);
      });
  };

  return (
    <React.Fragment>
      <div className="page-content">
        <div className="container-fluid">
          <Row>
            <Col lg="12">
              <Card>
                <CardBody className="border-bottom py-2">
                  <div>
                    <Row className="d-flex align-items-center">
                      <Col lg="4">
                        <h5 className=" text-color-gredient"> Products </h5>
                      </Col>
                      <Col
                        lg="8"
                        className="d-flex align-items-center justify-content-end"
                      >
                        <div className="search-box me-xxl-2 my-3 my-xxl-0 d-inline-block">
                          <div className="position-relative">
                            <label
                              htmlFor="search-bar-0"
                              className="search-label mb-0"
                            >
                              <span id="search-bar-0-label" className="sr-only">
                                Search this table
                              </span>
                              <input
                                id="search-bar-0"
                                type="text"
                                className="form-control"
                                placeholder={`Search Product...`}
                                style={{ borderRadius: "4px" }}
                                onChange={handleSearchClick}
                              />
                            </label>
                            <i className="bx bx-search-alt search-icon"></i>
                          </div>
                        </div>
                        <div className="me-xxl-2 my-3 my-xxl-0 d-inline-block">
                          <select
                            className="form-control select2 mb-xxl-0"
                            style={{ width: "165px" }}
                            onChange={handleStatusFilter}
                          >
                            <option value="">All Products</option>
                            <option value="highTolow">High to Low</option>
                            <option value="lowTohigh">Low to High</option>
                          </select>
                        </div>
                        <div className="me-xxl-2 my-3 my-xxl-0 d-inline-block">
                          <select
                            className="form-control select2 mb-xxl-0"
                            style={{ width: "165px" }}
                            onChange={handleCategoryFilter}
                          >
                            <option value="">All Categories</option>
                            {categories?.map((item) => (
                              <option key={item?._id} value={item?._id}>
                                {item?.name}
                              </option>
                            ))}
                          </select>
                        </div>
                      </Col>
                    </Row>
                  </div>
                </CardBody>
                <CardBody>
                  <Table
                    id="tech-companies-1"
                    className="table table-striped table-bordered"
                  >
                    <Thead>
                      <Tr>
                        <Th>Sr. No.</Th>
                        <Th>Product</Th>
                        <Th>User </Th>
                        {/* <Th>Quantity</Th> */}
                        <Th>Price</Th>
                        <Th>Created At</Th>
                        <Th>Status</Th>
                        {/* <Th> View </Th> */}
                      </Tr>
                    </Thead>
                    <Tbody>
                      {loaderStatus ? (
                        <Tr>
                          <Td colSpan="6"> Loading ... </Td>
                        </Tr>
                      ) : listingData.length != 0 ? (
                        listingData.map((element, index) => {
                          currentPage > 1
                            ? (incermentInd = (currentPage - 1) * perPage + 1)
                            : 0;
                          return (
                            <tr key={index}>
                              <td>
                                <span className="co-name">
                                  {" "}
                                  {incermentInd + index}{" "}
                                </span>
                              </td>
                              <td>
                                <div className="d-flex align-items-center">
                                  <div className="me-1">
                                    <img
                                      src={
                                        element?.thumbnailDoc
                                          ? element?.thumbnailDoc
                                          : element?.media?.[0]
                                      }
                                      alt=""
                                      className="avatar-md rounded-circle img-thumbnail"
                                    />
                                  </div>

                                  <div className="text-muted">
                                    <h5 className="mb-0">
                                      {" "}
                                      {element?.title
                                        ? firstLetterCapital(element?.title)
                                        : ""}{" "}
                                    </h5>
                                    <span>
                                      <Badge
                                        color={
                                          element?.sellingType == "physical"
                                            ? "success"
                                            : "info"
                                        }
                                        className="px-2"
                                      >
                                        {firstLetterCapital(
                                          element?.sellingType
                                        )}
                                      </Badge>
                                    </span>
                                  </div>
                                </div>
                              </td>
                              <td>
                                <div className="d-flex align-items-center">
                                  <div className="me-1">
                                    <img
                                      src={
                                        element?.user?.image
                                          ? element?.user?.image
                                          : avatar
                                      }
                                      alt=""
                                      className="avatar-md rounded-circle img-thumbnail"
                                    />
                                  </div>

                                  <div className="text-muted">
                                    <h5 className="mb-0">
                                      {" "}
                                      {element?.user?.name
                                        ? element.user.name
                                        : ""}{" "}
                                    </h5>
                                    <span>
                                      {element?.user?.email
                                        ? element.user.email
                                        : ""}
                                    </span>
                                  </div>
                                </div>
                              </td>
                              {/* <td>{element.quantity}</td> */}
                              <td>{element.price} HOMMIES</td>

                              <td>{dateformat(element.createdAt)}</td>
                              <td>
                                {console.log(
                                  Boolean(element.isActive),
                                  ">>>>>>>>>>>>>"
                                )}
                                <div className="square-switch mytoggle">
                                  <input
                                    type="checkbox"
                                    id={`square-switch${element._id}`}
                                    switch="success"
                                    defaultChecked={Boolean(element.isActive)}
                                    value={element._id}
                                    onClick={handleChangeCheck}
                                  />
                                  <label
                                    htmlFor={`square-switch${element._id}`}
                                    data-on-label="Active"
                                    data-off-label="Inactive"
                                  />
                                </div>
                              </td>
                              {/* <td>
                                <ul className="list-unstyled hstack gap-1 mb-0">
                                  <li>
                                    <Link
                                      to={{
                                        pathname: `/admin/users/detail`,
                                        state: { userDetail: element },
                                      }}
                                      className="btn btn-sm btn-soft-primary"
                                    >
                                      <i className="mdi mdi-eye" />
                                    </Link>
                                  </li>
                                </ul>
                              </td> */}
                            </tr>
                          );
                        })
                      ) : (
                        <tr>
                          <td colSpan={6}>No Records Found</td>
                        </tr>
                      )}
                    </Tbody>
                  </Table>

                  <Row className="mt-2">
                    <Col md={9}></Col>
                    <Col md={3}>
                      <Pagination
                        {...bootstrap5PaginationPreset}
                        current={currentPage}
                        total={totalCount}
                        onPageChange={(page) => handlePageClick(page)}
                        className="pagination justify-content-end"
                      />
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      </div>
    </React.Fragment>
  );
};

ProductsList.propTypes = {
  t: PropTypes.any,
  chartsData: PropTypes.any,
  onGetChartsData: PropTypes.func,
};

export default withTranslation()(ProductsList);
