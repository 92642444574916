import React from 'react';
import {
    Modal,
} from "reactstrap";

export const Preview = ({ selectedPreview, previewStatus, setPreviewStatus, setSelectedPreview }) => {

    return (
        <React.Fragment>

            <Modal
                size="xl"
                isOpen={previewStatus}
                toggle={() => { setPreviewStatus(false); setSelectedPreview(null) }}
                className="modal-fullscreen"
            >
                <div className="modal-header">
                    <h5
                        className="modal-title mt-0"
                        id="exampleModalFullscreenLabel"
                    >
                        {selectedPreview?.name}
                    </h5>
                    <button
                        onClick={() => { setPreviewStatus(false); setSelectedPreview(null); }}
                        type="button"
                        className="close"
                        data-dismiss="modal"
                        aria-label="Close"
                    >
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div className="modal-body text-center">
                    <img src={selectedPreview?.file} style={{height: "100%"}} />
                </div>
            </Modal>

        </React.Fragment>
    );
};
