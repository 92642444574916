import PropTypes, { element, func } from "prop-types";
import React, { useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import {
  Card,
  CardBody,
  Col,
  Row,
  Container,
  Badge,
  CardTitle,
  FormGroup,
  Input,
  InputGroup,
} from "reactstrap";
import { Table, Thead, Tbody, Tr, Th, Td } from "react-super-responsive-table";
import "../../assets/scss/style.css";
import { Modal, Form, Button } from "react-bootstrap";
import { useFormik } from "formik";
import * as Yup from "yup";

import "flatpickr/dist/themes/material_blue.css";

import { withTranslation } from "react-i18next";

import { apiRoute } from "routes/apiRoutes";
import { LoaderContext } from "context/ContextProvider";
import axios from "axios";
import { toast } from "react-toastify";
import {
  dateformat,
  handleClickCopy,
  longStringConvertToShort,
} from "admin/commonFunction";
import Pagination, {
  bootstrap5PaginationPreset,
} from "react-responsive-pagination";
import { getAllUser, updateUserDetail } from "services/userServices";

const KycUsers = (props) => {
  const {
    Add,
    Edit,
    showEdit,
    handleShow,
    handelShow1,
    handleClose,
    setloading,
  } = useContext(LoaderContext);

  document.title = "Users";
  const BASE_URL = process.env.REACT_APP_BASE_URL;
  const [dateFilter, setDateFilter] = useState([]);
  const [listingData, setData] = useState([]);
  const [searchName, setSearchName] = useState("");
  const [statusFilter, setStatusFilter] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [totalCount, setTotalCount] = useState(0);
  const [loaderStatus, setLoaderStatus] = useState(true);
  let [incermentInd, setIncrementInd] = useState(1);
  const perPage = 10;

  //fetch users
  const fetchData = (pagenumber, pagelimit, search = "", status = "") => {
    try {
      setLoaderStatus(true);
      // axios
      //   .get(
      //     apiRoute.getUserListing +
      //       "?page=" +
      //       pagenumber +
      //       "&limit=" +
      //       pagelimit +
      //       "&search=" +
      //       search +
      //       "&userStatus=" +
      //       status
      //   )

      getAllUser(
        pagenumber,
        pagelimit,
        search,
        "",
        "",
        status,
        "INPROGRESS"
      ).then((res) => {
        console.log(res, "user listing >>>>>>");
        setData(res.data.items);

        let pageCount = Math.ceil(res.data.totalItems / perPage);
        setTotalCount(pageCount);
        setLoaderStatus(false);
      });
    } catch (err) {
      setLoaderStatus(false);
      console.log(err);
    }
  };

  useEffect(() => {
    fetchData(currentPage, perPage);
  }, []);

  const handlePageClick = (page) => {
    setCurrentPage(page);
    fetchData(page, perPage, searchName, statusFilter);
  };

  const [typingTimeout, setTypingTimeout] = useState(0);
  const handleSearchClick = (e) => {
    const value = e.target.value;
    setSearchName(value);
    setCurrentPage(1);
    clearTimeout(typingTimeout);
    const timeoutId = setTimeout(() => {
      fetchData(1, perPage, e.target.value, statusFilter);
    }, 500);

    setTypingTimeout(timeoutId);

    // setSearchName(event.target.value)
    // setCurrentPage(1)
    // fetchData(1, perPage, event.target.value, statusFilter)
  };

  const handleStatusFilter = (e) => {
    console.log(e.target.value);
    setStatusFilter(e.target.value);
    if (e.target.value != "") {
      fetchData(1, perPage, searchName, e.target.value);
    } else {
      fetchData(1, perPage, searchName, "");
    }
  };

  const [modal, setmodal] = useState(false);
  const toggleModal = () => {
    setmodal(!modal);
  };
  //register user
  const handleSubmit = (values) => {
    console.log(values, "ac");
    const data = new FormData();
    for (const key in values) {
      data.append(key, values[key]);
    }
    setloading(true);
    try {
      axios
        .post(apiRoute.registerUser, data)
        .then((res) => {
          console.log(res.data.message, "jhkkl;");
          setloading(false);
          toast.success(res.data.message);
          toggleModal();
          fetchData(1, perPage);
        })
        .catch((err) => {
          console.log(err, "gfhgjkjlk");
          setloading(false);
          toast.error(err.response.data.error);
        });
    } catch (err) {
      console.log(err, "hkjhkjkh");
      setloading(false);
      toast.error("An error occurred !");
    }
  };

  const deleteUser = (user_id) => {
    try {
      axios.delete(apiRoute.deleteUser + `?userID=${user_id}`).then((res) => {
        console.log(res);
        toast.success("User Deleted Successfully");
        fetchData(1, perPage);
      });
    } catch (err) {
      console.log(err);
      toast.error("An error occurred !");
    }
  };

  //delete Job
  const ConfirmDeleteUser = (userId) => {
    // let URL = BASE_URL + "user/delete/" + userId
    Swal.fire({
      title: "Are you sure?",
      text: "you want to delete this user!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        deleteUser(userId);
      }
    });
  };

  const handleChangeCheck = async (event) => {
    console.log(event.target.value);
    console.log(event.target.checked);

    let userID = event.target.value;
    let isApproved = true;
    if (!event.target.checked) {
      isApproved = false;
    }

    let data = {
      isBlocked: isApproved.toString(),
      id: userID,
    };
    updateUserDetail(data)
      .then(function (response) {
        toast.success(response.data.message);
      })
      .catch(function (error) {
        toast.error(error.response.data.error);
      });
  };

  const formik = useFormik({
    initialValues: {
      name: "",
      email: "",
      password: "",
      confirmpassword: "",
      profileImg: null,
    },
    validationSchema: Yup.object({
      name: Yup.string().required("name is required"),
      email: Yup.string().required("email is required"),
      password: Yup.string().required("password is required"),
      confirmpassword: Yup.string()
        .required("confirm password is required")
        .oneOf([Yup.ref("password"), null], "passwords must match"),
      profileImg: Yup.mixed().required("profile image is required."),
    }),
    onSubmit: (values) => {
      handleSubmit(values);
    },
  });
  useEffect(() => {
    formik.resetForm();
  }, [modal]);

  return (
    <React.Fragment>
      <div className="page-content">
        <div className="container-fluid">
          <Row>
            <Col lg="12">
              <Card>
                <CardBody className="border-bottom py-2">
                  <div>
                    <Row className="d-flex align-items-center">
                      <Col lg="4">
                        <h5 className=" text-color-gredient">KYC Users </h5>
                      </Col>
                      <Col
                        lg="8"
                        className="d-flex align-items-center justify-content-end"
                      >
                        <div className="search-box me-xxl-2 my-3 my-xxl-0 d-inline-block">
                          <div className="position-relative">
                            <label
                              htmlFor="search-bar-0"
                              className="search-label mb-0"
                            >
                              <span id="search-bar-0-label" className="sr-only">
                                Search this table
                              </span>
                              <input
                                id="search-bar-0"
                                type="text"
                                className="form-control"
                                placeholder={`Search Name...`}
                                style={{ borderRadius: "4px" }}
                                onChange={handleSearchClick}
                              />
                            </label>
                            <i className="bx bx-search-alt search-icon"></i>
                          </div>
                        </div>
                        <div className="me-xxl-2 my-3 my-xxl-0 d-inline-block">
                          <select
                            className="form-control select2 mb-xxl-0"
                            style={{ width: "165px" }}
                            onChange={handleStatusFilter}
                          >
                            <option value="">All Users</option>
                            <option value="1">Blocked</option>
                            <option value="0">Unblocked</option>
                          </select>
                        </div>
                      </Col>
                    </Row>
                  </div>
                </CardBody>
                <CardBody>
                  <Table
                    id="tech-companies-1"
                    className="table table-striped table-bordered"
                  >
                    <Thead>
                      <Tr>
                        <Th>Sr. No.</Th>
                        <Th>Name</Th>
                        <Th>Wallet Address</Th>
                        <Th>Phone Number</Th>
                        <Th>Created At</Th>
                        <Th> KYC</Th>
                        <Th>Status</Th>
                        <Th> View </Th>
                      </Tr>
                    </Thead>
                    <Tbody>
                      {loaderStatus ? (
                        <Tr>
                          <Td colSpan="6"> Loading ... </Td>
                        </Tr>
                      ) : listingData.length != 0 ? (
                        listingData.map((element, index) => {
                          currentPage > 1
                            ? (incermentInd = (currentPage - 1) * perPage + 1)
                            : 0;
                          return (
                            <tr key={index}>
                              <td>
                                <span className="co-name">
                                  {" "}
                                  {incermentInd + index}{" "}
                                </span>
                              </td>
                              <td>
                                <div className="d-flex align-items-center">
                                  <div className="">
                                    <h5 className="font-size-14 text-color-gredient">
                                      {element.name}
                                    </h5>
                                    <span>{element.email}</span>
                                  </div>
                                </div>
                              </td>
                              <td>
                                {element.walletAddress ? (
                                  <>
                                    {longStringConvertToShort(
                                      element.walletAddress,
                                      30
                                    )}
                                    <span
                                      onClick={() =>
                                        handleClickCopy(element.walletAddress)
                                      }
                                      style={{
                                        cursor: "pointer",
                                        fontSize: "18px",
                                      }}
                                      className="ms-2"
                                    >
                                      <i className="mdi mdi-content-copy"></i>
                                    </span>
                                  </>
                                ) : (
                                  "-"
                                )}
                              </td>
                              <td>{element.phoneNumber}</td>
                              <td>{dateformat(element.createdAt)}</td>
                              <td>
                                {element &&
                                element?.kycStatus == "INPROGRESS" ? (
                                  <Badge color="primary" className="me-1">
                                    {" "}
                                    Inprogress{" "}
                                  </Badge>
                                ) : element &&
                                  element?.kycStatus == "COMPLETED" ? (
                                  <Badge color="success" className="me-1">
                                    {" "}
                                    Completed{" "}
                                  </Badge>
                                ) : element &&
                                  element?.kycStatus == "REJECTED" ? (
                                  <Badge color="danger" className="me-1">
                                    {" "}
                                    Rejected{" "}
                                  </Badge>
                                ) : (
                                  <Badge color="warning" className="me-1">
                                    {" "}
                                    Pending{" "}
                                  </Badge>
                                )}
                              </td>
                              <td>
                                <div className="square-switch mytoggle">
                                  <input
                                    type="checkbox"
                                    id={`square-switch${element._id}`}
                                    switch="success"
                                    defaultChecked={Boolean(element.isBlocked)}
                                    value={element._id}
                                    onClick={handleChangeCheck}
                                  />
                                  <label
                                    htmlFor={`square-switch${element._id}`}
                                    data-on-label="Block"
                                    data-off-label="Unblock"
                                  />
                                </div>
                              </td>
                              <td>
                                <ul className="list-unstyled hstack gap-1 mb-0">
                                  <li>
                                    <Link
                                      to={{
                                        pathname: `/admin/users/detail`,
                                        state: { userDetail: element },
                                      }}
                                      className="btn btn-sm btn-soft-primary"
                                    >
                                      <i className="mdi mdi-eye" />
                                    </Link>
                                  </li>
                                </ul>
                              </td>
                            </tr>
                          );
                        })
                      ) : (
                        <tr>
                          <td colSpan={8}>No Records Found</td>
                        </tr>
                      )}
                    </Tbody>
                  </Table>

                  <Row className="mt-2">
                    <Col md={9}></Col>
                    <Col md={3}>
                      <Pagination
                        {...bootstrap5PaginationPreset}
                        current={currentPage}
                        total={totalCount}
                        onPageChange={(page) => handlePageClick(page)}
                        className="pagination justify-content-end"
                      />
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      </div>
    </React.Fragment>
  );
};

KycUsers.propTypes = {
  t: PropTypes.any,
  chartsData: PropTypes.any,
  onGetChartsData: PropTypes.func,
};

export default withTranslation()(KycUsers);
