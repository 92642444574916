import classnames from "classnames"
import React, { useContext, useEffect, useState } from "react"
import ReactApexChart from "react-apexcharts"
import { useLocation, useParams } from "react-router-dom"
import {
    Button,
    Card,
    CardBody,
    Col,
    Container,
    Input,
    InputGroup,
    Label,
    Nav,
    NavItem,
    NavLink,
    Row,
    TabContent,
    TabPane
} from "reactstrap"
import Badge from 'react-bootstrap/Badge';

//Simple bar
import SimpleBar from "simplebar-react"

//Import Breadcrumb

//Import Images
import { dateTimeformat, dateformat, handleClickCopy, listingOfMyStaking, longStringConvertToShort } from "admin/commonFunction"
import avatar from "../../../assets/images/users/dummy.png"
import img3 from "../../../assets/images/background-user.png"
import { Link } from "react-router-dom"
import { BlockChainContext } from "context/blockChainContext"
import { ethers } from "ethers"
import { config } from "config/blockChainConfig"
import axios from "axios"
import { JobWidgetCharts } from "./chart"
import { kycStatusUpdate, userKycInfo, userStorageInfo, userTransactionsInfo } from "services/userServices"
import noKYC from "../../../assets/images/nokyc.jpg"

import Breadcrumbs from "../../../components/Common/Breadcrumb";
import { fireToast } from "common/toast"
import { dateFormat, dateOnlyFormat } from "common/utility"
import { Preview } from "./preview"

const CryptoExchange = props => {

    //meta title
    document.title = "User Detail"

    const location = useLocation();
    const { userDetail } = location.state;

    useEffect(() => {

        console.log(userDetail, "<<--- userID")
        getDetailsCrypto()
    }, [userDetail]);

    const [selectedPreview, setSelectedPreview] = useState()
    const [previewStatus, setPreviewStatus] = useState(false)
    const [hommiesBalance, setHommiesBalance] = useState(0)
    const [stakingInvest, setStakingInvest] = useState(0)
    const [stakingMature, setStakingMature] = useState(0)
    const [graphStake, setGraphStake] = useState([0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0])
    const getDetailsCrypto = async () => {
        try {
            let provider = new ethers.providers.JsonRpcProvider(config.publicProviderKey)
            let hommiesContractInstance = new ethers.Contract(config.hommiesContractAddress, config.hommiesAbi, provider)

            if (userDetail?.walletAddress) {
                let balance = await hommiesContractInstance.balanceOf(userDetail?.walletAddress)
                let decodeBalnace = ethers.utils.formatEther(balance.toString());
                console.log(decodeBalnace, "decodeBalnace")
                setHommiesBalance(decodeBalnace)

                let stakingContractInstance = new ethers.Contract(config.stakeContractAddress, config.stakeAbi, provider)
                const listingOfStakingPlans = await stakingContractInstance.getAllStakingDataByAddress(userDetail?.walletAddress)
                // console.log(listingOfStakingPlans, "<<--- listingOfStakingPlans")

                let { stakingArr, stakingHistoryArr, allStaking, totalInvestment, totalMaturity, earningGraph } = await listingOfMyStaking(listingOfStakingPlans)

                setStakingInvest(totalInvestment)
                setStakingMature(totalMaturity)
                setGraphStake(earningGraph)
            }

        } catch (error) {
            console.log("error --->>", error)
        }
    }

    const [hommiesUSD, setHommiesUSD] = useState(0)
    const [storageInfo, setStorageInfo] = useState(null)
    const [transListing, setTransListing] = useState([])
    const [kycInfo, setKycInfo] = useState(null)
    useEffect(() => {

        axios.get("https://api.geckoterminal.com/api/v2/networks/eth/pools/0x106b3f201f7b152b3239d2d443b1b1743b108b74")
            .then((response) => {
                setHommiesUSD(response?.data?.data?.attributes?.base_token_price_usd ?? 0)
            });

        userStorageInfo(userDetail?._id)
            .then((response) => {
                setStorageInfo(response?.data ? response?.data : null)
            }).catch((error) => {
                console.log("user info error", error)
            })

        userTransactionsInfo(userDetail?._id)
            .then((response) => {
                setTransListing(response?.data?.item ? response?.data?.item : [])
            }).catch((error) => {
                console.log("user info error", error)
            })

        getKYCinfo()
    }, [])

    const getKYCinfo = async () => {

        userKycInfo(userDetail?._id)
            .then((response) => {
                console.log(response, "<<---- response kyc")
                setKycInfo(response.data)
            }).catch((error) => {
                console.log("user kyc error", error)
            })
    }

    const handleKYCstatus = async (status) => {
        try {

            Swal.fire({
                title: "Are you sure?",
                text: "You won't be able to revert this!",
                icon: "warning",
                showCancelButton: true,
                confirmButtonColor: "#3085d6",
                cancelButtonColor: "#d33",
                confirmButtonText: "Yes, do it!",
            }).then(async (result) => {
                if (result.isConfirmed) {
                    let response = await kycStatusUpdate({ kycStatus: status, userId: userDetail?._id, reason: "" })
                    if (response.status == 200) {
                        fireToast("success", status == "completed" ? "KYC approved!" : "KYC Rejected!", "Success!")
                        getKYCinfo()
                    }
                }
            })

        } catch (error) {
            fireToast("error", error.message, "Error!")
        }
    };

    const handleKYCstatusReject = async (status) => {
        try {

            Swal.fire({
                title: "Are you sure?",
                text: "You won't be able to revert this!",
                icon: "warning",
                showCancelButton: true,
                confirmButtonColor: "#3085d6",
                cancelButtonColor: "#d33",
                confirmButtonText: "Yes, do it!",
                input: "textarea",
                inputPlaceholder: "Reason for rejection...",
                inputAttributes: {
                    autocapitalize: "off"
                },
                showLoaderOnConfirm: true
            }).then(async (result) => {
                console.log(result, "<<-- -result")
                if (result.isConfirmed) {
                    let response = await kycStatusUpdate({ kycStatus: status, userId: userDetail?._id, reason: result.value })
                    if (response.status == 200) {
                        fireToast("success", status == "completed" ? "KYC approved!" : "KYC Rejected!", "Success!")
                        getKYCinfo()
                    }
                }
            });

        } catch (error) {
            fireToast("error", error.message, "Error!")
        }
    };

    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>
                    {/* Render Breadcrumb */}
                    <Breadcrumbs title="Users" breadcrumbItem="User Detail" />



                    <Row>
                        <Col xl="4">

                            <Card>
                                <CardBody>
                                    <div className="d-flex">

                                        <div className="d-flex align-items-center">
                                            <div className="me-3">
                                                <img
                                                    src={userDetail?.image ? userDetail?.image : avatar}
                                                    alt=""
                                                    className="avatar-md rounded-circle img-thumbnail"
                                                />
                                            </div>

                                            <div className="text-muted">
                                                <h5> {userDetail ? userDetail?.name : ""} </h5>
                                                {
                                                    userDetail && userDetail?.walletAddress ?
                                                        <>
                                                            <b className="mb-0"> <i className="mdi mdi-wallet"></i> {longStringConvertToShort(userDetail?.walletAddress, 30)}  <i role="button" onClick={() => handleClickCopy(userDetail?.walletAddress)} className="mdi mdi-content-copy ms-2"></i> </b>
                                                        </> : ""
                                                }
                                            </div>
                                        </div>

                                    </div>
                                </CardBody>
                                <CardBody className="border-top py-4">

                                    <ul className="list-unstyled mt-2">
                                        <li>
                                            <div className="d-flex">
                                                {
                                                    (kycInfo && kycInfo?.kyc_status == "PENDING") ?
                                                        <i className="bx bx-error-alt text-primary fs-4"></i>
                                                        : kycInfo?.kyc_status == "COMPLETED" ?
                                                            <i className="bx bx-check-shield text-primary fs-4"></i>
                                                            :
                                                            <i className="bx bx-error-alt text-primary fs-4"></i>
                                                }

                                                <div className="ms-3">
                                                    <h6 className="fs-14 mb-2"> KYC Status</h6>
                                                    <p className="text-muted fs-14 mb-0">
                                                        {
                                                            (kycInfo && kycInfo?.kyc_status == "PENDING") ?
                                                                "Pending"
                                                                : kycInfo?.kyc_status == "COMPLETED" ?
                                                                    "Completed"
                                                                    : kycInfo?.kyc_status == "REJECTED" ?
                                                                        "Rejected"
                                                                        :
                                                                        "Inprogress"
                                                        }
                                                    </p>
                                                </div>
                                            </div>
                                        </li>
                                        <li className="mt-3">
                                            <div className="d-flex">
                                                <i className="bx bx-phone text-primary fs-4"></i>
                                                <div className="ms-3">
                                                    <h6 className="fs-14 mb-2">Phone</h6>
                                                    <p className="text-muted fs-14 mb-0"> {userDetail?.phoneNumber} </p>
                                                </div>
                                            </div>
                                        </li>
                                        <li className="mt-3">
                                            <div className="d-flex">
                                                <i className="bx bx-mail-send text-primary fs-4"></i>
                                                <div className="ms-3">
                                                    <h6 className="fs-14 mb-2">Email</h6>
                                                    <p className="text-muted fs-14 mb-0">{userDetail?.email} </p>
                                                </div>
                                            </div>
                                        </li>
                                        <li className="mt-3">
                                            <div className="d-flex">
                                                <i className="bx bx-map text-primary fs-4"></i>
                                                <div className="ms-3">
                                                    <h6 className="fs-14 mb-2">Location</h6>
                                                    <p className="text-muted fs-14 mb-0">{userDetail?.city}</p>
                                                </div>
                                            </div>
                                        </li>
                                        <li className="mt-3">
                                            <div className="d-flex">
                                                <i className="bx bx-globe text-primary fs-4"></i>
                                                <div className="ms-3">
                                                    <h6 className="fs-14 mb-2">Created On</h6>
                                                    <p className="text-muted fs-14 text-break mb-0"> {dateformat(userDetail?.createdAt)} </p>
                                                </div>
                                            </div>
                                        </li>
                                    </ul>

                                </CardBody>
                            </Card>
                        </Col>
                        <Col xl="8">
                            <Row>
                                <Col sm="4">
                                    <Card className="mini-stats-wid">
                                        <CardBody className="ps-3 pe-1">
                                            <div className="d-flex">
                                                <div className="avatar-sm mx-auto me-3">
                                                    <span className="avatar-title rounded-circle bg-warning bg-soft text-warning">
                                                        <i className="mdi mdi-signal-hspa h3 mb-0" />
                                                    </span>
                                                </div>

                                                <div className="flex-grow-1">
                                                    <p className="text-muted mb-2"> Hommies </p>
                                                    <h5 className="mb-0">
                                                        {hommiesBalance > 0 ? Number(hommiesBalance).toFixed(3) : 0} {" "}
                                                        <span className="font-size-14 text-muted">
                                                            = $ {(hommiesBalance * hommiesUSD).toFixed(2)}
                                                        </span>
                                                    </h5>
                                                </div>
                                            </div>
                                        </CardBody>
                                    </Card>
                                </Col>
                                <Col sm="4">
                                    <Card className="mini-stats-wid">
                                        <CardBody className="ps-3 pe-1">
                                            <div className="d-flex">
                                                <div className="avatar-sm mx-auto me-3">
                                                    <span className="avatar-title rounded-circle bg-danger bg-soft text-danger">
                                                        <i className="bx bxs-layer h3 mb-0" />
                                                    </span>
                                                </div>
                                                <div className="flex-grow-1">
                                                    <p className="text-muted mb-2">Staking Investment</p>
                                                    <h5 className="mb-0">
                                                        {stakingInvest ? Number(stakingInvest).toFixed(3) : 0} {" "}
                                                        <span className="font-size-14 text-muted">
                                                            = $ {(stakingInvest * hommiesUSD).toFixed(2)}
                                                        </span>
                                                    </h5>
                                                </div>
                                            </div>
                                        </CardBody>
                                    </Card>
                                </Col>
                                <Col sm="4">
                                    <Card className="mini-stats-wid">
                                        <CardBody className="ps-3 pe-1">
                                            <div className="d-flex">
                                                <div className="avatar-sm mx-auto me-3">
                                                    <span className="avatar-title rounded-circle bg-success bg-soft text-success">
                                                        <i className="fas fa-chart-line h4 mb-0" />
                                                    </span>
                                                </div>
                                                <div className="flex-grow-1">
                                                    <p className="text-muted mb-2">Staking Maturity</p>
                                                    <h5 className="mb-0">
                                                        {stakingMature ? Number(stakingMature).toFixed(3) : 0} {" "}
                                                        <span className="font-size-14 text-muted">
                                                            = $ {(stakingMature * hommiesUSD).toFixed(2)}
                                                        </span>
                                                    </h5>
                                                </div>
                                            </div>
                                        </CardBody>
                                    </Card>
                                </Col>
                            </Row>


                            <Card>
                                <CardBody>

                                    <Row>
                                        <Col md={6}>
                                            <h4 className="card-title mb-3">KYC Verification</h4>
                                        </Col>
                                        <Col md={6} className="text-end">

                                            {
                                                (kycInfo && kycInfo?.kyc_status == "INPROGRESS") ?

                                                    <div>
                                                        <div
                                                            className="btn-group btn-group-example mb-3"
                                                            role="group"
                                                        >
                                                            <button
                                                                type="button"
                                                                className="btn btn-primary w-xs px-4"
                                                                onClick={() => handleKYCstatus("completed")}
                                                            >
                                                                <i className="mdi mdi-thumb-up"></i> Approve
                                                            </button>
                                                            <button
                                                                type="button"
                                                                className="btn btn-danger w-xs px-4"
                                                                onClick={() => handleKYCstatusReject("rejected")}
                                                            >
                                                                <i className="mdi mdi-thumb-down"></i> Reject
                                                            </button>
                                                        </div>
                                                    </div>
                                                    : (kycInfo && kycInfo?.kyc_status == "COMPLETED") ?

                                                        <div>
                                                            <button
                                                                type="button"
                                                                className="btn btn-success  btn-sm mb-4"
                                                            >
                                                                <i className="bx bx-check-double font-size-16 align-middle me-2"></i>{" "}
                                                                Approved
                                                            </button>
                                                        </div>
                                                        : (kycInfo && kycInfo?.kyc_status == "REJECTED") ?
                                                            <div>
                                                                <button
                                                                    type="button"
                                                                    className="btn btn-danger btn-sm mb-2"
                                                                >
                                                                    <i className="bx bx-block font-size-16 align-middle me-2"></i>{" "}
                                                                    Rejected
                                                                </button>
                                                            </div>
                                                            :
                                                            <div>
                                                                <button
                                                                    type="button"
                                                                    className="btn btn-warning btn-sm mb-4"
                                                                >
                                                                    <i className="bx bx-error font-size-16 align-middle me-2"></i>{" "}
                                                                    Pending
                                                                </button>
                                                            </div>
                                            }
                                            {
                                                (kycInfo && kycInfo?.kyc_status == "REJECTED" && kycInfo?.detail?.reason) && <p> <b>Reason: </b> <span className="text-danger">{kycInfo?.detail?.reason}</span> </p>
                                            }

                                        </Col>
                                        {
                                            (kycInfo && kycInfo?.detail) ?
                                                <>
                                                    <Col md={4}>

                                                        <ul className="list-unstyled mt-2">
                                                            <li>
                                                                <div className="d-flex">
                                                                    <i className="bx bx-user-circle text-primary fs-4"></i>
                                                                    <div className="ms-3">
                                                                        <h6 className="fs-14 mb-2">Name</h6>
                                                                        <p className="text-muted fs-14 mb-0"> {kycInfo?.detail?.firstName} </p>
                                                                    </div>
                                                                </div>
                                                            </li>
                                                            <li className="mt-3">
                                                                <div className="d-flex">
                                                                    <i className="bx bx-calendar-event text-primary fs-4"></i>
                                                                    <div className="ms-3">
                                                                        <h6 className="fs-14 mb-2"> Date of Birth </h6>
                                                                        <p className="text-muted fs-14 mb-0"> {dateOnlyFormat(kycInfo?.detail?.dateOfBirth)} </p>
                                                                    </div>
                                                                </div>
                                                            </li>
                                                            <li className="mt-3">
                                                                <div className="d-flex">
                                                                    <i className="bx bx-user text-primary fs-4"></i>
                                                                    <div className="ms-3">
                                                                        <h6 className="fs-14 mb-2"> Gender </h6>
                                                                        <p className="text-muted fs-14 mb-0"> {kycInfo?.detail?.gender.toUpperCase()}  </p>
                                                                    </div>
                                                                </div>
                                                            </li>
                                                            <li className="mt-3">
                                                                <div className="d-flex">
                                                                    <i className="bx bx-world text-primary fs-4"></i>
                                                                    <div className="ms-3">
                                                                        <h6 className="fs-14 mb-2"> Nationality </h6>
                                                                        <p className="text-muted fs-14 mb-0"> {kycInfo?.detail?.nationality.toUpperCase()}  </p>
                                                                    </div>
                                                                </div>
                                                            </li>
                                                        </ul>

                                                    </Col>
                                                    <Col md={4}>

                                                        <ul className="list-unstyled mt-2">
                                                            <li>
                                                                <div className="d-flex">
                                                                    <i className="bx bx-map-pin text-primary fs-4"></i>
                                                                    <div className="ms-3">
                                                                        <h6 className="fs-14 mb-2">Residential Address</h6>
                                                                        <p className="text-muted fs-14 mb-0"> {kycInfo?.detail?.address}  </p>
                                                                    </div>
                                                                </div>
                                                            </li>
                                                            <li className="mt-3">
                                                                <div className="d-flex">
                                                                    <i className="bx bx-map-pin text-primary fs-4"></i>
                                                                    <div className="ms-3">
                                                                        <h6 className="fs-14 mb-2"> Country </h6>
                                                                        <p className="text-muted fs-14 mb-0"> {kycInfo?.detail?.country} </p>
                                                                    </div>
                                                                </div>
                                                            </li>
                                                            <li className="mt-3">
                                                                <div className="d-flex">
                                                                    <i className="bx bx-map-pin text-primary fs-4"></i>
                                                                    <div className="ms-3">
                                                                        <h6 className="fs-14 mb-2"> State </h6>
                                                                        <p className="text-muted fs-14 mb-0"> {kycInfo?.detail?.state} </p>
                                                                    </div>
                                                                </div>
                                                            </li>
                                                            <li className="mt-3">
                                                                <div className="d-flex">
                                                                    <i className="bx bx-map-pin text-primary fs-4"></i>
                                                                    <div className="ms-3">
                                                                        <h6 className="fs-14 mb-2"> Zip Code </h6>
                                                                        <p className="text-muted fs-14 mb-0"> {kycInfo?.detail?.zip} </p>
                                                                    </div>
                                                                </div>
                                                            </li>
                                                        </ul>

                                                    </Col>
                                                    <Col md={4}>
                                                        <div className="d-flex justify-content-between align-items-center">
                                                            <label>Front Side</label>
                                                            <Badge bg="success">{kycInfo?.detail?.documentType.toUpperCase()} </Badge>
                                                        </div>
                                                        <span onClick={() => { setSelectedPreview({file: kycInfo?.detail?.frontImage, name: userDetail?.name }); setPreviewStatus(true) }} style={{ textAlign: "center", display: "block" }} role="button">
                                                            <img className="img-thumbnail" alt="200x200" src={kycInfo?.detail?.frontImage} data-holder-rendered="true" style={{ height: "98px" }} />
                                                        </span>

                                                        <div className="mt-2 d-flex justify-content-between align-items-center">
                                                            <label>Back Side</label>
                                                            <Badge bg="success">{kycInfo?.detail?.documentType.toUpperCase()} </Badge>
                                                        </div>
                                                        <span onClick={() => { setSelectedPreview({file: kycInfo?.detail?.backImage, name: userDetail?.name }); setPreviewStatus(true) }} style={{ textAlign: "center", display: "block" }} role="button">
                                                            <img className="img-thumbnail" alt="200x200" src={kycInfo?.detail?.backImage} data-holder-rendered="true" style={{ height: "98px" }} />
                                                        </span>
                                                    </Col>
                                                </>
                                                :
                                                <Col md={12}>
                                                    <div className="text-center my-2">
                                                        <img src={noKYC} height={205} />
                                                        <h5> KYC Pending </h5>
                                                    </div>
                                                </Col>
                                        }

                                    </Row>

                                    {/* <h4 className="card-title mb-3">User Earning</h4> */}
                                    {/* <div>
                                        <div id="overview-chart" dir="ltr">
                                            <JobWidgetCharts graphStake={graphStake} />
                                        </div>
                                    </div> */}
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>

                    <Row>
                        <Col lg="12">
                            <Card>
                                <CardBody>
                                    <div className="d-flex">
                                        <div className="flex-grow-1 align-self-center">
                                            <div className="d-flex">
                                                <div className="avatar-sm me-3">
                                                    <span className="avatar-title rounded-circle bg-success bg-soft text-primary font-size-22">
                                                        <i className="bx bx-shield-quarter" />
                                                    </span>
                                                </div>

                                                <div className="text-muted">
                                                    <h5> {storageInfo?.planInfo ? "Premium" : "Basic"} Account </h5>
                                                    <p className="mb-1"> {storageInfo?.planInfo ? storageInfo?.planInfo?.title : "Basic"} Plan </p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="flex-grow-1 align-self-center">
                                            <div className="d-flex">
                                                <div className="avatar-sm me-3">
                                                    <span className="avatar-title rounded-circle bg-success bg-soft text-primary font-size-22">
                                                        <i className="mdi mdi-database" />
                                                    </span>
                                                </div>

                                                <div className="text-muted">
                                                    <h5> Total Storage </h5>
                                                    <p className="mb-1"> {storageInfo?.TotalStorage} GB </p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="flex-grow-1 align-self-center">
                                            <div className="d-flex">
                                                <div className="avatar-sm me-3">
                                                    <span className="avatar-title rounded-circle bg-warning bg-soft text-warning font-size-22">
                                                        <i className="mdi mdi-database-minus text-danger" />
                                                    </span>
                                                </div>

                                                <div className="text-muted">
                                                    <h5> Occupied Storage </h5>
                                                    <p className="mb-1"> {storageInfo?.totalPinSizeInGB > 1 ? storageInfo?.totalPinSizeInGB.toFixed(2) + " GB" : storageInfo?.totalPinSizeInMB > 1 ? storageInfo?.totalPinSizeInMB.toFixed(2) + " MB" : storageInfo?.totalPinSizeInKB ? storageInfo?.totalPinSizeInKB.toFixed(2) + "KB" : ""} </p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="flex-grow-1 align-self-center">
                                            <div className="d-flex">
                                                <div className="avatar-sm me-3">
                                                    <span className="avatar-title rounded-circle bg-warning bg-soft text-warning font-size-22">
                                                        <i className="mdi mdi-database-plus text-success" />
                                                    </span>
                                                </div>

                                                <div className="text-muted">
                                                    <h5> Unoccupied Storage </h5>
                                                    <p className="mb-1"> {(storageInfo?.TotalStorage - storageInfo?.totalPinSizeInGB).toString().slice(0, 5)} GB </p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>

                    <Row>
                        <Col xl="12">
                            <Card>
                                <CardBody>
                                    <h4 className="card-title mb-4"> Transaction History </h4>

                                    <div className="table-responsive">
                                        <table className="table table-nowrap align-middle mb-0">
                                            <thead>
                                                <tr>
                                                    <th scope="col">Plan</th>
                                                    <th scope="col">Storage</th>
                                                    <th scope="col">Token</th>
                                                    <th scope="col">Transaction ID</th>
                                                    <th scope="col">Status</th>
                                                    <th> Expiry Date </th>
                                                    <th scope="col" colSpan="2">
                                                        Detail
                                                    </th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {transListing.length == 0 ?
                                                    <tr>
                                                        <td colSpan={7}>
                                                            <div className="text-muted"> No Transaction </div>
                                                        </td>
                                                    </tr>
                                                    : transListing.map((asset, key) => (
                                                        <tr key={key}>
                                                            <td>
                                                                <div className="text-muted"> {asset?.plan?.title ? asset?.plan?.title : "-"} </div>
                                                            </td>
                                                            <td>
                                                                <div className="text-muted"> {asset?.orders?.storage ? asset?.orders?.storage : "0"} GB </div>
                                                            </td>
                                                            <th scope="row">
                                                                <div className="d-flex align-items-center">
                                                                    <div className="avatar-xs me-3">
                                                                        <span className="avatar-title rounded-circle bg-soft bg-warning text-warning font-size-18">
                                                                            <i className="mdi mdi-signal-hspa" />
                                                                        </span>
                                                                    </div>

                                                                    <div className="flex-grow-1">
                                                                        <p className="text-muted mb-1"> Hommies </p>
                                                                        <div className="text-muted" style={{ fontWeight: "400" }}> {asset.amount} </div>
                                                                    </div>
                                                                </div>
                                                            </th>
                                                            <td>
                                                                <div className="text-muted">
                                                                    {longStringConvertToShort(asset?.transctionHash, 30)}  <i role="button" onClick={() => handleClickCopy(asset?.transctionHash)} className="mdi mdi-content-copy ms-2"></i>
                                                                </div>
                                                            </td>
                                                            <td>
                                                                <div className="text-muted">
                                                                    {asset.transactionStatus == "Completed" ? <Badge bg="success">COMPLETED</Badge> : <Badge bg="warning" className="text-black">PENDING</Badge>}
                                                                </div>
                                                            </td>
                                                            <td>
                                                                <div className="text-muted">
                                                                    {asset.transactionStatus == "Completed" ? dateTimeformat(asset?.orders?.planEndDate) : "-"}
                                                                </div>
                                                            </td>
                                                            <td style={{ width: "120px" }}>
                                                                <a
                                                                    href={`${config.scanURL}/${asset?.transctionHash}`}
                                                                    className="btn btn-primary btn-sm w-xs"
                                                                // target="_blank"
                                                                // rel="noopener"
                                                                >
                                                                    View
                                                                </a>
                                                            </td>
                                                        </tr>
                                                    ))}
                                            </tbody>
                                        </table>
                                    </div>
                                </CardBody>
                            </Card>
                        </Col>
                        {/* <Col xl="6">
                            <Card>
                                <CardBody>
                                    <h4 className="card-title mb-4">Notifications</h4>

                                    <SimpleBar style={{ maxHeight: "310px" }}>
                                        <ul className="verti-timeline list-unstyled">
                                            {notofications.map((notification, key) => (
                                                <li key={key} className="event-list">
                                                    <div className="event-timeline-dot">
                                                        <i className="bx bx-right-arrow-circle font-size-18" />
                                                    </div>
                                                    <div className="d-flex">
                                                        <div className="me-3">
                                                            <h5 className="font-size-14">
                                                                {notification.date}{" "}
                                                                <i className="bx bx-right-arrow-alt font-size-16 text-primary align-middle ms-2" />
                                                            </h5>
                                                        </div>
                                                        <div className="flex-grow-1">
                                                            <div>{notification.desc}</div>
                                                        </div>
                                                    </div>
                                                </li>
                                            ))}
                                        </ul>
                                    </SimpleBar>
                                </CardBody>
                            </Card>
                        </Col> */}
                    </Row>

                        <Preview
                            selectedPreview={selectedPreview}
                            previewStatus={previewStatus}
                            setPreviewStatus={setPreviewStatus}
                            setSelectedPreview={setSelectedPreview}
                        />

                </Container>
            </div>
        </React.Fragment>
    )
}

export default CryptoExchange
