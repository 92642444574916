import React, { useContext, useEffect, useState } from "react"
import Button from "react-bootstrap/Button"
import { Modal, Form } from "react-bootstrap"
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { withRouter, Link } from "react-router-dom"
import {
  Card,
  CardBody,
  Col,
  Row,
  Container,
  Badge,
  CardTitle,
  FormGroup,
  Input,
  InputGroup,
} from "reactstrap"
import { Table, Thead, Tbody, Tr, Th, Td } from "react-super-responsive-table"
import { LoaderContext } from "context/ContextProvider"
import axios from "axios"
import { element } from "prop-types"
import { apiHeader, apiRoute } from "helpers/api_helper";
import {  updateNewPlan, addNewPlans , deletePlanData, getPlanDetail } from "services/userServices";
import { alerShow } from "admin/commonFunction";
function Plan() {
  const {
    Add,
    Edit,
    showEdit,
    handleShow,
    handelShow1,
    handleClose,
    setloading,
  } = useContext(LoaderContext)
  const [Data, setdata] = useState()
  const [edit, setEdit] = useState({
    id: "",
    Question: "",
    Answer: "",
  })
  console.log();
  const handleFaq = async (values) => {
    setloading(true)
    try {
      await addNewPlans({
        title: values.title,
        price: values.price,
        storage: values.storage,
        content: values.content
      }).then(res => {
        console.log(res)
        setloading(false)
        alerShow("Success",  "New Plan Added Successfully", "success");
        handleClose()
        fetchData()
      })
    } catch (err) {
      console.log(err)
      setloading(false)
      alerShow("Error!", "An error occurred !", "Error");
    }
  }

  const editPlan = (id, title, price,storage, content) => {
    handleShow()
    setEdit({ id, title, price , storage,content })
  }

  const updatePlan = async (e) => {
    e.preventDefault()
    let bodyData = {
      id: edit.id,
      title: edit.title,
      price: edit.price,
      storage: edit.storage,
      content: edit.content
    }
    setloading(true)
    try {
      await updateNewPlan({
        title: bodyData.title,
        price: bodyData.price,
        content: bodyData.content,
        storage: bodyData.storage,
        id: edit.id
      }).then(res => {
        console.log(res)
        setloading(false)
        handleClose()
        alerShow("Success",  "Plan Updated Successfully", "success");
        fetchData()
      })
    } catch (err) {
      console.log(err)
      setloading(false)
      alerShow("Error!", "An error occurred !", "Error");
    }
  }

  const fetchData = async () => {
    try {
      await getPlanDetail().then(res => {
        console.log(res.data, "hfghg")
        setdata(res.data)
      })
    } catch (err) {
      console.log(err)
    }
  }

  useEffect(() => {
    fetchData()
  }, [])



  const deleteFAQ = async (faq_id) => {
    try {

      await deletePlanData(faq_id).then(res => {
        console.log(res)
        alerShow("Success",  "Plan Deleted Successfully", "success");
        fetchData()
      })
    } catch (err) {
      console.log(err)
      alerShow("Error!", "An error occurred !", "Error");
    }
  }

  const ConfirmDelete = id => {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then(result => {
      if (result.isConfirmed) {
        deleteFAQ(id)
      }
    })
  }



  const formik = useFormik({
    initialValues: {
      title: '',
      content: '',
      storage:'',
      price:''
    },
    validationSchema: Yup.object({
      title: Yup.string()
        .required('Tile is required'),
        content: Yup.string()
        .required('Content is required'),
        price: Yup.number().required('Amount is required'),
        storage: Yup.number().required('Amount is required')

    }),
    onSubmit: values => {
      handleFaq(values);
    },
  });
  useEffect(() => {
    formik.resetForm();
  }, [Add])

  return (
    <>
      <div className="page-content">
        <div className="container-fluid">
          <Row>
            <Col lg="12">
              <Card>
                <CardBody className="border-bottom py-2">
                  <div className="d-flex align-items-center">
                    <h5 className="mb-0 card-title flex-grow-1 text-color-gredient">
                      {" "}
                      Storage Plans
                    </h5>
                    <div style={{ marginRight: '0px !important' }}>
                      <button
                        type="button"
                        className="btn btn-secondary mt-3"
                        onClick={handelShow1}
                      >
                        <i className="bx bx-plus"></i> Add plans
                      </button>
                    </div>
                  </div>
                </CardBody>

                <CardBody style={{ padding: 16 }}>
                  <div className="table-responsive">
                    <Table
                      id="tech-companies-1"
                      className="table table-striped table-bordered"
                    >
                      <Thead>
                        <Tr>
                          <Th>Sr.No</Th>
                          <Th> Plan Name</Th>
                          <Th> Amount </Th>
                          <Th> Description </Th>
                          <Th> Storage (In GB) </Th>
                          <Th> Action </Th>
                        </Tr>
                      </Thead>
                      <Tbody>
                        {Data?.map((element, index) => {
                          return (
                            <tr key={index}>
                              <td style={{ paddingLeft: 15 }}>
                                <span className="co-name"> {index + 1}.</span>
                              </td>
                              <td>{element.title}</td>
                              <td>{element.price}</td>
                              <td>{element.content}</td>
                              <td>{element.storage}</td>
                              <td>
                                <ul className="list-unstyled hstack gap-1 mb-0">
                                  <li>
                                    <Link
                                      onClick={() => {
                                        editPlan(
                                          element._id,
                                          element.title,
                                          element.price,
                                          element.storage, 
                                          element.content
                                        )
                                      }}
                                      className="btn btn-sm btn-soft-primary"
                                    >
                                      <i className="mdi mdi-pencil" />
                                    </Link>
                                  </li>
                                  <li>
                                    <Link
                                      to="#"
                                      className="btn btn-sm btn-soft-danger"
                                      onClick={() => {
                                        ConfirmDelete(element._id)
                                      }}
                                    >
                                      <i
                                        className="mdi mdi-delete-outline"
                                        id="deletetooltip"
                                      />
                                    </Link>
                                  </li>
                                </ul>
                              </td>
                            </tr>
                          )
                        })}
                      </Tbody>
                    </Table>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      </div>
      <Modal
        size='lg'
        show={Add}
        onHide={handleClose}
        aria-labelledby="contained-modal-title-vcenter"
        backdrop="static"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>Add Plan</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form onSubmit={formik.handleSubmit}>
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
              <Form.Label>Tittle</Form.Label>
              <Form.Control
                type="text"
                name="title"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.title}
                placeholder=""
                autoFocus='true'
              />
              {formik.touched.title && formik.errors.title ? (
                <div style={{ color: 'red' }}>{formik.errors.title}</div>
              ) : null}
            </Form.Group>
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
              <Form.Label>Price</Form.Label>
              <Form.Control
                type="number"
                min={0}
                name="price"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.price}
                placeholder=""
                autoFocus='true'
              />
              {formik.touched.price && formik.errors.price ? (
                <div style={{ color: 'red' }}>{formik.errors.price}</div>
              ) : null}
            </Form.Group>
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
              <Form.Label>Storage</Form.Label>
              <Form.Control
                type="number"
                min={0}
                name="storage"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.storage}
                placeholder=""
                autoFocus='true'
              />
              {formik.touched.storage && formik.errors.storage ? (
                <div style={{ color: 'red' }}>{formik.errors.storage}</div>
              ) : null}
            </Form.Group>
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
              <Form.Label>Content</Form.Label>
              <Form.Control
                as="textarea"
                name="content"
                rows="8"

                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.content}
                placeholder=""
                aria-label="With textarea"

              />
              {formik.touched.content && formik.errors.content ? (
                <div style={{ color: 'red' }}>{formik.errors.content}</div>
              ) : null}

            </Form.Group>
            <Button variant="secondary" onClick={handleClose}>
              Close
            </Button>
            <Button type="submit" variant="primary" style={{ marginLeft: 10 }}>
              ADD
            </Button>
          </Form>
        </Modal.Body>
        <Modal.Footer></Modal.Footer>
      </Modal>

      <Modal
        size="lg"
        show={Edit}
        onHide={handleClose}
        aria-labelledby="contained-modal-title-vcenter"
        backdrop="static"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>Edit Plan</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form onSubmit={updatePlan}>
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
              <Form.Label>Title</Form.Label>
              <Form.Control
                type="text"
                value={edit ? edit.title : ""}
                onChange={e => {
                  setEdit({ ...edit, title: e.target.value })
                }}
                name="title"
                placeholder=""
                autoFocus='true'
                controlId="exampleForm.ControlTextarea1"
              />
            </Form.Group>
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
              <Form.Label>Price</Form.Label>
              <Form.Control
                type="number"
                value={edit ? edit.price : ""}
                onChange={e => {
                  setEdit({ ...edit, price: e.target.value })
                }}
                name="price"
                placeholder=""
                autoFocus='true'
                controlId="exampleForm.ControlTextarea1"
              />
            </Form.Group>
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
              <Form.Label>Storage</Form.Label>
              <Form.Control
                type="number"
                value={edit ? edit.storage : ""}
                onChange={e => {
                  setEdit({ ...edit, storage: e.target.value })
                }}
                name="price"
                placeholder=""
                autoFocus='true'
                controlId="exampleForm.ControlTextarea1"
              />
            </Form.Group>
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
              <Form.Label>Content</Form.Label>
              <Form.Control
                as="textarea"
                value={edit ? edit.content : ""}
                onChange={e => {
                  setEdit({ ...edit, content: e.target.value })
                }}
                name="content"
                aria-label="With textarea"
                rows={8}
              />
            </Form.Group>
           
            <Button variant="secondary" onClick={handleClose}>
              Close
            </Button>
            <Button variant="primary" type="submit" style={{ marginLeft: 10 }}>
              Update
            </Button>
          </Form>
        </Modal.Body>
        <Modal.Footer></Modal.Footer>
      </Modal>
    </>
  )
}
export default withRouter(Plan)
