import React, { useState, useContext } from "react";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import { LoaderContext } from "context/ContextProvider.js";
import Modal from "react-bootstrap/Modal";
import { fireToast } from "common/toast";
import { useFormik } from "formik";
import * as Yup from "yup";
import { createCategory } from "services/helper";
const AddCategory = ({
    open,
    setOpen,
    fetchData,
    edit,
}) => {
    const initialValues = {
        name: edit.name ? edit.name : "",
        type: edit.type ? edit.type : "physical",
    };
    const formik = useFormik({
        initialValues: initialValues,
        enableReinitialize: true,
        validationSchema: Yup.object({
            name: Yup.string().required("Required"),
            type: Yup.string().required("Required")
        }),
        onSubmit: async (values, { resetForm }) => {
            try {
                console.log(values, "<<--- values")
                const response = await createCategory({ ...values, id: edit?._id });
                if (response.status == 200) {
                    setOpen(false)
                    fireToast("success", response.message);
                    resetForm()
                    fetchData()
                }
            } catch (error) {
                fireToast("error", error.response.data.error);
            }
        },
    });
    const [SubCategory, setSubcategory] = useState([{
        subCategory: ""
    }])

    return (
        <>
            <Modal show={open} onHide={() => setOpen(false)}>
                <Modal.Header closeButton>
                    <Modal.Title>{edit ? "Update Category" : "Add Category"}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <form className="" onSubmit={formik.handleSubmit}>
                        <div className="row">
                            <div className="col-md-12">
                                <select className="form-control" name="type" onChange={formik.handleChange} onBlur={formik.handleBlur}>
                                    <option value="physical"> Physical Product </option>
                                    <option value="digital"> Digital Product </option>
                                    <option value="subscription"> Subscription Product </option>
                                </select>
                                {formik.touched.type && formik.errors.type ? (
                                    <div className="text-danger">{formik.errors.type}</div>
                                ) : null}
                            </div>
                            <div className="col-md-10">
                                <input
                                    className={`form-control  my-3`}
                                    type="text"
                                    placeholder="Write here..."
                                    onChange={formik.handleChange}
                                    value={formik?.values?.name}
                                    onBlur={formik.handleBlur}
                                    id="name"
                                />
                                {formik.touched.name && formik.errors.name ? (
                                    <div className="text-danger">{formik.errors.name}</div>
                                ) : null}
                            </div>
                            <div className="col-md-2 p-0">
                                <button
                                    type="submit"
                                    className="btn btn-secondary my-3"
                                >
                                    {edit ? "Update" : "Add"}
                                </button>
                            </div>
                        </div>
                    </form>

                </Modal.Body>
            </Modal>
        </>
    );
}

export default AddCategory;
