import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import {
  Card,
  CardBody,
  Col,
  Row,
  Container,
  Badge,
  CardTitle,
  Label,
  Button,
  Form,
  Input,
  InputGroup,
  FormFeedback,
} from "reactstrap";
import ReactChipInput from "react-chip-input";
import { TagsInput } from "react-tag-input-component";
import { alerShow } from "../commonFunction";
import axios from "axios";
import * as Yup from "yup";
import { useFormik } from "formik";

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";

//i18n
import { withTranslation } from "react-i18next";
import "../../assets/scss/style.css";
import {
  changePassword,
  getUserProfile,
  updateProfile,
} from "services/userServices";

const Support = (props) => {
  //meta title
  document.title = "Support";

  const BASE_URL = process.env.REACT_APP_BASE_URL;
  const [details, setDetails] = useState({
    phone_number: "",
    email: "",
    contactSupportEmails: [],
    kycSupportEmails: [],
  });
  const changeProfile = (e) => {
    setDetails({ ...details, [e.target.name]: e.target.value });
  };

  // Form validation
  const profileValidation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      email: details?.email,
      phone_number: details?.phone_number,
      contactSupportEmails: details?.contactSupportEmails,
      kycSupportEmails: details?.kycSupportEmails,
    },
    validationSchema: Yup.object({
      email: Yup.string().required("Please Enter Your Support Email"),
      phone_number: Yup.string().required("Please Enter Phone Number"),
      contactSupportEmails: Yup.array().min(
        1,
        "Please Enter Contact Support Emails"
      ),
      kycSupportEmails: Yup.array().min(1, "Please Enter KYC Support Emails"),
    }),
    onSubmit: (values, actions) => {
      let collectionData = {
        phoneNumber: values.phone_number,
        email: values.email,
        contactSupportEmails: values.contactSupportEmails,
        kycSupportEmails: values.kycSupportEmails,
        name: "Admin",
      };
      updateProfile(collectionData)
        .then(function (response) {
          console.log(response, "responseresponse");
          alerShow("success", response.message, "Success");
          actions.resetForm(values);
        })
        .catch(function (error) {
          alerShow("error", error.response.data.error, "Error");
        });
    },
  });

  const getAdminProfile = async () => {
    try {
      let detail = await getUserProfile();
      if (detail.status == 200) {
        setDetails({
          email: detail.data.supportEmail,
          phone_number: detail.data.phoneNumber,
          kycSupportEmails: detail.data?.kycSupportEmails ?? [],
          contactSupportEmails: detail.data?.contactSupportEmails ?? [],
        });
      }
    } catch (error) {
      console.log(error, "error profile api");
    }
  };

  useEffect(() => {
    getAdminProfile();
  }, []);

  return (
    <React.Fragment>
      <div className="page-content margin-custom">
        <Container fluid>
          {/* Render Breadcrumb */}
          <Breadcrumbs
            title={props.t("Dashboard")}
            breadcrumbItem={props.t("Support Notifications")}
          />

          <Row>
            <Col xl="12">
              <Card>
                <CardBody>
                  <CardTitle className="h5 mb-4">
                    {" "}
                    Change Support Details{" "}
                  </CardTitle>

                  <Form
                    className="needs-validation create-vendor"
                    onSubmit={(e) => {
                      e.preventDefault();
                      profileValidation.handleSubmit();
                      return false;
                    }}
                  >
                    <Row>
                      <Col md={6}>
                        <div className="mb-3">
                          <Label>Support Email</Label>
                          <Input
                            type="email"
                            name="email"
                            className="form-control"
                            placeholder="Write here..."
                            value={details.email}
                            onBlur={profileValidation.handleBlur}
                            onChange={changeProfile}
                          />
                          {profileValidation.touched.email &&
                          profileValidation.errors.email ? (
                            <FormFeedback type="invalid">
                              {profileValidation.errors.email}
                            </FormFeedback>
                          ) : null}
                        </div>
                      </Col>
                      <Col md={6}>
                        <div className="mb-3">
                          <Label>Support Phone Number</Label>
                          <Input
                            type="number"
                            name="phone_number"
                            className="form-control"
                            placeholder="Write here..."
                            value={details.phone_number}
                            onBlur={profileValidation.handleBlur}
                            onChange={changeProfile}
                          />
                          {profileValidation.touched.phone_number &&
                          profileValidation.errors.phone_number ? (
                            <FormFeedback type="invalid">
                              {profileValidation.errors.phone_number}
                            </FormFeedback>
                          ) : null}
                        </div>
                      </Col>
                      <Col md={6}>
                        <div className="mb-3">
                          <Label>Contact Support Emails</Label>

                          <TagsInput
                            value={details.contactSupportEmails}
                            onChange={(e) => {
                              profileValidation.setFieldValue(
                                "contactSupportEmails",
                                e
                              );
                            }}
                            name="contactSupportEmails"
                            placeHolder="Write here..."
                          />

                          {profileValidation.touched.contactSupportEmails &&
                          profileValidation.errors.contactSupportEmails ? (
                            <FormFeedback type="invalid">
                              {profileValidation.errors.contactSupportEmails}
                            </FormFeedback>
                          ) : null}
                        </div>
                      </Col>
                      <Col md={6}>
                        <div className="mb-3">
                          <Label>KYC Support Emails</Label>
                          <TagsInput
                            value={details.kycSupportEmails}
                            onChange={(e) => {
                              profileValidation.setFieldValue(
                                "kycSupportEmails",
                                e
                              );
                            }}
                            name="kycSupportEmails"
                            placeHolder="Write here..."
                          />

                          {profileValidation.touched.kycSupportEmails &&
                          profileValidation.errors.kycSupportEmails ? (
                            <FormFeedback type="invalid">
                              {profileValidation.errors.kycSupportEmails}
                            </FormFeedback>
                          ) : null}
                        </div>
                      </Col>
                    </Row>

                    <div>
                      <button
                        type="submit"
                        className="btn btn-secondary w-md mt-3"
                      >
                        Submit
                      </button>
                    </div>
                  </Form>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

Support.propTypes = {
  t: PropTypes.any,
  chartsData: PropTypes.any,
  onGetChartsData: PropTypes.func,
};

export default withTranslation()(Support);
