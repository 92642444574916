import PropTypes, { element, func } from "prop-types";
import React, { useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import {
  Card,
  CardBody,
  Col,
  Row,
  Container,
  Badge,
  CardTitle,
  FormGroup,
  Input,
  InputGroup,
} from "reactstrap";
import { Table, Thead, Tbody, Tr, Th, Td } from "react-super-responsive-table";
import { firstLetterCapital } from "common/utility";
import "../../assets/scss/style.css";
import "flatpickr/dist/themes/material_blue.css";
import avatar from "../../assets/images/users/dummy.png";
import { getAllProjectListing, deleteProjectData } from "services/helper";
import { withTranslation } from "react-i18next";
import { apiRoute } from "routes/apiRoutes";
import { alerShow } from "../commonFunction";
import { LoaderContext } from "context/ContextProvider";
import axios from "axios";
import { toast } from "react-toastify";
import {
  dateformat,
  handleClickCopy,
  longStringConvertToShort,
} from "admin/commonFunction";
import Pagination, {
  bootstrap5PaginationPreset,
} from "react-responsive-pagination";
import { useHistory } from "react-router";

const ProjectList = (props) => {
  const {
    Add,
    Edit,
    showEdit,
    handleShow,
    handelShow1,
    handleClose,
    setloading,
  } = useContext(LoaderContext);
  const history = useHistory();
  document.title = "Projects";
  const BASE_URL = process.env.REACT_APP_BASE_URL;
  const [listingData, setData] = useState([]);
  const [searchName, setSearchName] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [totalCount, setTotalCount] = useState(0);
  const [loaderStatus, setLoaderStatus] = useState(true);
  let [incermentInd, setIncrementInd] = useState(1);
  const perPage = 10;

  const handleCreate = () => {
    history.push("/admin/add-project");
  };

  const editProject = (data) => {
    history.push("/admin/add-project", data);
  };

  //fetch users
  const fetchData = async (pagenumber = 1, pagelimit = 10, search = "") => {
    try {
      setLoaderStatus(true);
      await getAllProjectListing(pagenumber, pagelimit, search).then((res) => {
        setData(res?.data?.items);
        let pageCount = Math.ceil(res.data.totalItems / perPage);
        setTotalCount(pageCount);
        setLoaderStatus(false);
      });
    } catch (err) {
      setLoaderStatus(false);
      console.log(err);
    }
  };

  useEffect(() => {
    fetchData(currentPage, perPage);
  }, []);

  const handlePageClick = (page) => {
    setCurrentPage(page);
    fetchData(page, perPage, searchName);
  };

  const [typingTimeout, setTypingTimeout] = useState(0);
  const handleSearchClick = (e) => {
    const value = e.target.value;
    setSearchName(value);
    setCurrentPage(1);
    clearTimeout(typingTimeout);
    const timeoutId = setTimeout(() => {
      fetchData(1, perPage, e.target.value);
    }, 500);

    setTypingTimeout(timeoutId);
  };

  const ConfirmDelete = (id) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        deleteProject(id);
      }
    });
  };

  const deleteProject = async (id) => {
    try {
      await deleteProjectData(id).then((res) => {
        alerShow("Success", "Project Deleted Successfully", "success");
        fetchData();
      });
    } catch (err) {
      console.log(err);
      alerShow("Error!", "An error occurred !", "Error");
    }
  };

  return (
    <React.Fragment>
      <div className="page-content">
        <div className="container-fluid">
          <Row>
            <Col lg="12">
              <Card>
                <CardBody className="border-bottom py-2">
                  <div>
                    <Row className="d-flex align-items-center">
                      <Col lg="4">
                        <h5 className=" text-color-gredient">
                          Projects Listing{" "}
                        </h5>
                      </Col>
                      <Col
                        lg="8"
                        className="d-flex align-items-center justify-content-end"
                      >
                        <div className="search-box me-xxl-2 my-3 my-xxl-0 d-inline-block">
                          <div className="position-relative">
                            <label
                              htmlFor="search-bar-0"
                              className="search-label mb-0"
                            >
                              <span id="search-bar-0-label" className="sr-only">
                                Search this table
                              </span>
                              <input
                                id="search-bar-0"
                                type="text"
                                className="form-control"
                                placeholder={`Search Product...`}
                                style={{ borderRadius: "4px" }}
                                onChange={handleSearchClick}
                              />
                            </label>
                            <i className="bx bx-search-alt search-icon"></i>
                          </div>
                        </div>
                        <div className="search-box me-xxl-2 my-3 my-xxl-0 d-inline-block">
                          <div className="position-relative">
                            {" "}
                            <button
                              type="button"
                              className="btn btn-secondary"
                              onClick={() => handleCreate()}
                            >
                              <i className="bx bx-plus"></i> Add New Project
                            </button>
                          </div>
                        </div>
                      </Col>
                    </Row>
                  </div>
                </CardBody>
                <CardBody>
                  <Table
                    id="tech-companies-1"
                    className="table table-striped table-bordered"
                  >
                    <Thead>
                      <Tr>
                        <Th>Sr. No.</Th>
                        <Th>Image</Th>
                        {/* <Th>Quantity</Th> */}
                        <Th>Title</Th>
                        <Th>Short Description</Th>
                        <Th>Contract Address</Th>
                        <Th>Created At</Th>
                        {/* <Th> View </Th> */}
                        <Th> Action </Th>
                      </Tr>
                    </Thead>
                    <Tbody>
                      {loaderStatus ? (
                        <Tr>
                          <Td colSpan="6"> Loading ... </Td>
                        </Tr>
                      ) : listingData.length != 0 ? (
                        listingData.map((element, index) => {
                          currentPage > 1
                            ? (incermentInd = (currentPage - 1) * perPage + 1)
                            : 0;
                          return (
                            <tr key={index}>
                              <td>
                                <span className="co-name">
                                  {" "}
                                  {incermentInd + index}{" "}
                                </span>
                              </td>
                              <td>
                                <div className="d-flex align-items-center">
                                  <div className="me-1">
                                    <img
                                      src={element?.image}
                                      alt=""
                                      className="avatar-md rounded-circle img-thumbnail"
                                    />
                                  </div>
                                </div>
                              </td>

                              {/* <td>{element.quantity}</td> */}
                              <td>{element.title}</td>
                              <td>{element.shortDesc}</td>
                              <td>
                                {longStringConvertToShort(
                                  element.contractAddress,
                                  20
                                )}
                                <span
                                  onClick={() =>
                                    handleClickCopy(element.contractAddress)
                                  }
                                  style={{
                                    cursor: "pointer",
                                    fontSize: "18px",
                                  }}
                                  className="ms-2"
                                >
                                  <i className="mdi mdi-content-copy"></i>
                                </span>
                              </td>

                              <td>{dateformat(element.createdAt)}</td>

                              <td>
                                <ul className="list-unstyled hstack gap-1 mb-0">
                                  <li>
                                    <Link
                                      onClick={() => {
                                        editProject(element);
                                      }}
                                      className="btn btn-sm btn-soft-primary"
                                    >
                                      <i className="mdi mdi-pencil" />
                                    </Link>
                                  </li>
                                  <li>
                                    <Link
                                      to="#"
                                      className="btn btn-sm btn-soft-danger"
                                      onClick={() => {
                                        ConfirmDelete(element._id);
                                      }}
                                    >
                                      <i
                                        className="mdi mdi-delete-outline"
                                        id="deletetooltip"
                                      />
                                    </Link>
                                  </li>
                                </ul>
                              </td>
                            </tr>
                          );
                        })
                      ) : (
                        <tr>
                          <td colSpan={6}>No Records Found</td>
                        </tr>
                      )}
                    </Tbody>
                  </Table>

                  <Row className="mt-2">
                    <Col md={9}></Col>
                    <Col md={3}>
                      <Pagination
                        {...bootstrap5PaginationPreset}
                        current={currentPage}
                        total={totalCount}
                        onPageChange={(page) => handlePageClick(page)}
                        className="pagination justify-content-end"
                      />
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      </div>
    </React.Fragment>
  );
};

ProjectList.propTypes = {
  t: PropTypes.any,
  chartsData: PropTypes.any,
  onGetChartsData: PropTypes.func,
};

export default withTranslation()(ProjectList);
