import { config } from "config/blockChainConfig";
import { ethers } from "ethers";
import React, { useState, useEffect } from "react";
import { createContext, useContext } from "react";

export const BlockChainContext = createContext();

const BlockChainContextProvider = ({ children }) => {

    const [provider, setProvider] = useState();
    const [singer, setSigner] = useState();
    const [walletAddress, setWalletAddress] = useState();
    const [stakingInstance, setStakingInstance] = useState();
    const [hommiesInstance, setHommiesInstance] = useState();

    const connectWallet = async () => {
        try {

            const providerStake = new ethers.providers.Web3Provider(window.ethereum)
            providerStake.send("eth_requestAccounts", [0])
            setProvider(providerStake)

            const signerStaking = providerStake.getSigner()
            setSigner(signerStaking)

            const address = await signerStaking.getAddress()
            setWalletAddress(address)

        } catch (error) {
            console.log("wallet connect error ------", error.message)
        }
    }

    const createStakingInstance = async () => {
        try {

            let stakeContractInstance = null;
            if (walletAddress && provider) {
                stakeContractInstance = new ethers.Contract(config.stakeContractAddress, config.stakeAbi, provider.getSigner())
                console.log(stakeContractInstance, "<<---- stakeContractInstance")
                setStakingInstance(stakeContractInstance)
            }
            return stakeContractInstance;

        } catch (error) {
            console.log("staking instance error ------", error.message)
        }
    }

    const createHommiesInstance = async () => {
        try {

            let hommiesContractInstance = null;
            if (walletAddress && provider) {
                hommiesContractInstance = new ethers.Contract(config.hommiesContractAddress, config.hommiesAbi, provider.getSigner())
                console.log(hommiesContractInstance, "<<---- hommiesContractInstance")
                setHommiesInstance(hommiesContractInstance)
            }
            return hommiesContractInstance;

        } catch (error) {
            console.log("staking instance error ------", error.message)
        }
    }

    return (
        <BlockChainContext.Provider value={{ provider, singer, walletAddress, stakingInstance, connectWallet, createStakingInstance, setProvider, setSigner, setWalletAddress, setStakingInstance, hommiesInstance, createHommiesInstance }}>
            {children}
        </BlockChainContext.Provider>
    )
}

export default BlockChainContextProvider




